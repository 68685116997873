import React from 'react';
import {Router, useLocation} from '@reach/router';
import {
	LoginView,
	Route,
	IndexView,
	RestorePasswordView,
	ResetPasswordVerificationCodeView,
	CreateNewPasswordView,
	LoginSuccessView,
	LoginAlreadyLinkedView,
	DetailsView,
	AuthenticatedRoute
} from 'src/components';
import {iframe, routes, env} from 'src/config';
import {useScript} from 'src/hooks';
import {analytics} from 'src/telemetry/analytics';

if (iframe.platformId) analytics.track(`platform id: ${iframe.platformId}`);

const App = () => {
	useScript(env.bootstrapCardUrl, 'pt-credit-card-script');
	const {pathname} = useLocation();
	// eslint-disable-next-line no-console
	if (env.environment === 'development') console.log(pathname);

	return (
		<Router>
			<Route path={routes.login} component={LoginView} />
			<Route path={routes.restorePassword} component={RestorePasswordView} />
			<Route path={routes.restorePasswordVerificationCode} component={ResetPasswordVerificationCodeView} />
			<Route path={routes.createNewPassword} component={CreateNewPasswordView} />
			<AuthenticatedRoute path={routes.detailsScreen} component={DetailsView} />
			<AuthenticatedRoute path={routes.alreadyLinkedScreen} component={LoginAlreadyLinkedView} />
			<AuthenticatedRoute path={routes.successScreen} component={LoginSuccessView} />
			<Route default path="/" component={IndexView} />
		</Router>
	);
};

export default App;
