import React from 'react';
import {makeStyles} from '@material-ui/styles';

interface Props {
	src: string;
	className?: string;
	height?: number;
}

const useStyles = makeStyles(() => ({
	emoji: {
		position: 'relative',
		top: 1,
		paddingLeft: 5
	}
}));

export const Emoji = ({src, className, height = 16}: Props) => {
	const classes = useStyles();
	return (
		<span className={className}>
			<img src={src} alt="src" className={classes.emoji} height={height} />
		</span>
	);
};
