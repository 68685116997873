import React, {FC, ReactNode} from 'react';
import {DesktopLayout, MobileLayout} from 'src/components/@shared';
import {isMobileDevice} from 'src/utils';
import {useSelector} from 'react-redux';
import {createLoadingSelector} from '../../../store/@loadings/selectors';
import {Loadings} from '../../../store/constants';
import {LoaderWithBackdrop} from '../../globalLoading';

interface Props {
	children: ReactNode;
	className?: string;
	withoutCloseButton?: boolean;
	noBackNavigation?: boolean;
	onBack?: () => void;
	onClose?: () => void;
}

export const HomeLayout: FC<Props> = ({
	children,
	className,
	withoutCloseButton,
	noBackNavigation = false,
	onBack,
	onClose
}: Props) => {
	const isLogoutLoader = useSelector(createLoadingSelector(Loadings.logoutUserLoading));

	if (isLogoutLoader) return <LoaderWithBackdrop isLoading={isLogoutLoader} />;
	return isMobileDevice() ? (
		<MobileLayout
			onBack={onBack}
			onClose={onClose}
			withoutCloseButton={withoutCloseButton}
			noBackNavigation={noBackNavigation}
			className={className}>
			{children}
		</MobileLayout>
	) : (
		<DesktopLayout withoutCloseButton={withoutCloseButton} className={className} onClose={onClose}>
			{children}
		</DesktopLayout>
	);
};
