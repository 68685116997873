import React, {ComponentType} from 'react';
import {isFunction} from 'lodash';
import {Formik, FormikValues, Form as FormikForm} from 'formik';
import {FormikConfig} from 'formik/dist/types';

interface Props {
	className?: string;
	component?: ComponentType;
}

export type FormProps<Values = unknown> = FormikConfig<Values> & Props;

type FormikFC = <Values extends FormikValues = FormikValues>(props: FormProps<Values>) => JSX.Element;

export const Form: FormikFC = ({
	children,
	className,
	component: Component = FormikForm,
	enableReinitialize = true,
	validateOnChange = true,
	validateOnBlur = true,
	...props
}) => (
	<Formik
		{...props}
		enableReinitialize={enableReinitialize}
		validateOnChange={validateOnChange}
		validateOnBlur={validateOnBlur}>
		{(formikBag) => (
			<Component className={className}>{isFunction(children) ? children(formikBag) : children}</Component>
		)}
	</Formik>
);
