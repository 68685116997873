import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Field, PasswordField, Button, Form, Spinner} from 'src/components/@shared';
import * as yup from 'yup';
import {isMobile} from 'src/utils';

const useStyles = makeStyles(() => ({
	root: {paddingTop: 20},
	input: {
		marginTop: 46
	},
	submitBtn: {
		margin: '96px 0 0'
	},
	mobileButton: {
		position: 'absolute',
		bottom: 50,
		width: '90%'
	}
}));

const lengthMismatchError = 'Password must be between 8 and 70 characters';

const validationSchema = yup.object().shape({
	password: yup
		.string()
		.min(8, lengthMismatchError)
		.max(70, lengthMismatchError)
		.test('empty spaces', 'The password cannot contain spaces', (value) => {
			if (!value) return true;
			return !value.includes(' ');
		})
		.required('Required'),
	passwordRepeat: yup
		.string()
		.min(8, lengthMismatchError)
		.max(70, lengthMismatchError)
		.test('empty spaces', 'The password cannot contain spaces', (value) => {
			if (!value) return true;
			return !value.includes(' ');
		})
		.oneOf([yup.ref('password'), undefined], 'Passwords must match')
		.required('Required')
});

const initialValues = {
	password: '',
	passwordRepeat: ''
};

export type CreateNewPassValues = {
	password: string;
	passwordRepeat: string;
};

interface Props {
	isLoading?: boolean;
	onSubmit: (values: CreateNewPassValues) => void;
}

export const CreateNewPasswordForm = ({isLoading, onSubmit}: Props) => {
	const classes = useStyles();

	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			className={classes.root}>
			<Field
				data-testid="createPassword"
				name="password"
				label="Create a new password"
				className={classes.input}
				fullWidth
				component={PasswordField}
			/>
			<Field
				data-testid="passwordRepeat"
				name="passwordRepeat"
				label="Confirm password"
				className={classes.input}
				fullWidth
				component={PasswordField}
			/>
			<Button
				disabled={isLoading}
				fullWidth
				type="submit"
				className={isMobile ? classes.mobileButton : classes.submitBtn}>
				{isLoading ? <Spinner color="mercury" size={19} /> : 'Done'}
			</Button>
		</Form>
	);
};
