import React, {FC, ReactText} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';
import clsx from 'clsx';
import {isMobile} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 70,
		backgroundColor: theme.colors.black30,
		textTransform: 'uppercase',
		borderRadius: 16,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'center'
	},
	label: {
		padding: isMobile ? '6px 0 4px 0' : '4px 0 4.5px 0',
		textAlign: 'center',
		color: theme.colors.white,
		fontSize: 13,
		lineHeight: '15px',
		margin: 0,
		fontFamily: 'Sofia Pro'
	}
}));

export interface BadgeProps {
	children: ReactText;
	className?: string;
}

export const Badge: FC<BadgeProps> = ({children, className}: BadgeProps) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className)}>
			<p className={classes.label}>{children}</p>
		</div>
	);
};
