import React, {ComponentType} from 'react';
import {RouteComponentProps} from '@reach/router';
import {makeStyles} from '@material-ui/styles';
import {trackComponent} from 'src/telemetry/AppInsights';
import {trackPageView} from 'src/telemetry/ecommerceWidgetEvents';

export interface RouteProps extends RouteComponentProps {
	isAuthenticated?: boolean;
	component: ComponentType<RouteComponentProps>;
}

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	}
}));

export const Route = ({component, isAuthenticated, ...props}: RouteProps) => {
	const classes = useStyles();
	trackPageView({name: component.name, uri: props.path, isLoggedIn: isAuthenticated});
	const Component = trackComponent(component, `${component.name} - ${props.path}`, classes.root);
	return <Component {...props} />;
};
