import {C, Errors, Loadings, Success} from '../constants';
import {LoadAction, PayloadAction} from '../types';
import api from '../../api';

export type PageType = 'resetPassword' | 'signup';

export type VerificationCodePendingPayload = {
	phone: string;
	pageType: PageType;
};

export type SendVerificationCodeAction = LoadAction<C.SEND_VERIFICATION_CODE> & {meta: VerificationCodePendingPayload};
export type SendVerificationCodeSuccessAction = PayloadAction<C.SEND_VERIFICATION_CODE_SUCCESS, string> & {
	meta: VerificationCodePendingPayload;
};
export type ValidatePhoneAction = LoadAction<C.VALIDATE_PHONE>;
export type ValidatePhoneSuccessAction = PayloadAction<C.VALIDATE_PHONE_SUCCESS, string>;

export const sendVerificationCode = (phone: string, pageType: PageType): SendVerificationCodeAction => ({
	type: C.SEND_VERIFICATION_CODE,
	load:
		pageType === 'resetPassword'
			? api.phoneVerification.sendVerificationCodeForResetPassword(phone)
			: api.phoneVerification.sendVerificationCodeForSignup(phone),
	loading: Loadings.isSendVerificationCodeLoading,
	error: Errors.isSendVerificationCodeError,
	success: Success.isSendVerificationCodeSuccessful,
	meta: {phone, pageType}
});

export const validatePhone = (phone: string, verificationCode: string): ValidatePhoneAction => ({
	type: C.VALIDATE_PHONE,
	load: api.phoneVerification.validatePhone({phone, verificationCode}),
	loading: Loadings.validatePhoneLoading,
	error: Errors.isPhoneValidationError,
	success: Success.isPhoneValidationSuccessful
});
