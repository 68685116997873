import {State} from '../reducer';
import {Errors} from '../constants';

export const SomethingWentWrongErrorsList = [Errors.loadAuthUserError];

export const selectErrors = (state: State) => state.errors;

export const selectCreateUserError = (state: State) => state.errors[Errors.loginUserError];

export const createErrorSelector =
	<T>(key: Errors) =>
	(state: State) =>
		state.errors[key] as T;

export const selectIsAnyFromSomethingWentWrongErrorsList = (state: State) =>
	SomethingWentWrongErrorsList.map((el) => state.errors[el]).filter(Boolean).length > 0;
