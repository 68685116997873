import React, {FC, HTMLProps, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {NumberFormatProps} from 'react-number-format';
import './phoneField.css';
import {Theme} from 'src/theme';

export type PhoneFieldPropsBase = Omit<
	HTMLProps<HTMLInputElement> & NumberFormatProps,
	'onChange' | 'type' | 'ref' | 'value' | 'defaultValue'
>;
export interface PhoneFieldProps extends PhoneFieldPropsBase {
	onChange: (phone: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		fontFamily: theme.fonts.primary,
		'& .special-label': {
			color: theme.colors.dustyGray
		},
		'& .search-box': {
			color: theme.colors.black,
			backgroundColor: theme.colors.white,
			'&:focus': {
				borderColor: theme.colors.purple
			}
		},
		'& .highlight': {
			backgroundColor: '#f1e9f6'
		},
		'& .country': {
			border: '0.5px solid rgba(207, 207, 207, 0.3)',
			borderRight: 'none',
			'&:hover': {
				backgroundColor: '#f1e9f6'
			}
		},
		'& .dial-code': {
			color: theme.colors.black50
		},
		'& .react-tel-input': {
			'&:focus-within': {
				'& .arrow': {
					borderColor: theme.colors.purple
				},
				'& .special-label': {
					color: theme.colors.purple
				}
			}
		}
	},
	input: {
		backgroundColor: theme.colors.white,
		color: theme.colors.black,
		borderBottom: `1px solid ${theme.colors.black20} !important`,
		fontFamily: theme.fonts.primary,
		'&:focus': {
			borderBottom: `1px solid ${theme.colors.purple} !important`
		}
	},
	dropdown: {
		boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15) !important',
		backgroundColor: `${theme.colors.white} !important`,
		color: theme.colors.black,
		fontFamily: theme.fonts.primary,
		'&::-webkit-scrollbar': {
			width: 4,
			backgroundColor: theme.colors.purple15
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.purple,
			borderBottomRightRadius: 2
		}
	},
	search: {
		backgroundColor: theme.colors.white
	},
	helperText: {
		'&:first-letter': {
			textTransform: 'uppercase'
		},
		color: theme.colors.dustyGray,
		fontSize: 12,
		marginTop: 4,
		lineHeight: '16px'
	},
	error: {
		color: `${theme.colors.red} !important`
	}
}));

export const PhoneField: FC<PhoneFieldPropsBase> = ({
	className,
	format,
	value,
	onChange,
	size,
	helperText,
	error,
	...props
}) => {
	const classes = useStyles();
	const [country, setCountry] = useState('us');

	return (
		<div className={clsx(classes.root, className)}>
			<PhoneInput
				{...props}
				placeholder="(702) 123-4567"
				disableCountryGuess
				countryCodeEditable={country === 'us'}
				disableCountryCode={country === 'us'}
				inputClass={classes.input}
				searchClass={classes.search}
				dropdownClass={classes.dropdown}
				value={value?.replace('+1', '')}
				country={country}
				specialLabel="Phone Number"
				enableSearch
				onChange={(val: string, countryData: any) => {
					setCountry(countryData.countryCode);
					onChange?.(`+${country === 'us' ? '1' : ''}${val}`);
				}}
			/>
			{!!helperText && <div className={clsx(classes.helperText, {[classes.error]: Boolean(error)})}>{helperText}</div>}
		</div>
	);
};
