import React, {HTMLAttributes} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex', // this fixes bug with extra padding in chrome
		backgroundColor: 'transparent',
		border: 'none',
		padding: 0,
		outline: 'none',
		opacity: 0.8,
		'&:hover': {
			transition: 'all 0.25s ease-out',
			opacity: 1,
			cursor: 'pointer'
		}
	}
}));

export interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {}

export const IconButton = ({className, children, ...props}: IconButtonProps) => {
	const classes = useStyles();
	return (
		<button {...props} type="button" className={clsx(classes.root, className)}>
			{children}
		</button>
	);
};
