import http from './http';

export type LinkingPermissionsResponse = {
	data: {
		status: number;
		msg: string;
	};
};

const linkingPermissions = {
	givePermissions(platformId: string): Promise<LinkingPermissionsResponse> {
		return (
			http
				.post('users/me/platform-invites', {
					platformId,
					allSpacesIncluded: true
				})
				// We need it in order to handle some exceptions not as exceptions, but as successful cases
				.then(() => Promise.resolve({data: {status: 204, msg: 'success'}}))
				.catch((error) => {
					if (error.data.status === 409) {
						return Promise.resolve({data: {status: 409, msg: 'already linked'}});
					}
					return Promise.reject(error);
				})
		);
	}
};

export default linkingPermissions;
