import {C} from 'src/store/constants';
import {SendVerificationCodeSuccessAction} from './actions';

export type PhoneVerificationActions = SendVerificationCodeSuccessAction;

export type PhoneVerificationState = {
	phone?: string;
};

const initialState: PhoneVerificationState = {};

const phoneVerificationReducer = (state = initialState, action: PhoneVerificationActions) => {
	switch (action.type) {
		case C.SEND_VERIFICATION_CODE_SUCCESS:
			return {
				...state,
				phone: action.meta.phone
			};
		default:
			return state;
	}
};

export default phoneVerificationReducer;
