import {all, put, select, takeEvery} from 'redux-saga/effects';
import {v4 as uuidv4} from 'uuid';
import {C, Errors, Success} from '../constants';
import {UpdatePasswordErrorAction} from './actions';
import {clearErrors, setError} from '../@errors/actions';
import {clearSuccess, setSuccess} from '../@success/actions';
import http, {setIdempotentToken} from '../../api/http';
import {selectNumberOfResetPasswordError, selectNumberOfResetPasswordNetworkError} from './selectors';

function* updatePasswordErrorSaga({payload: {error}}: UpdatePasswordErrorAction) {
	if (!error?.response && !error?.status) {
		const IdempotentHeaderId = uuidv4();
		const savedIdempotentToken = http.defaults.headers.common['X-Idempotent-Id'];
		if (!savedIdempotentToken) {
			setIdempotentToken(IdempotentHeaderId);
		}
		const numberOfNetworkErrors: number = yield select(selectNumberOfResetPasswordNetworkError);
		yield put(setError(Errors.updatePasswordNetworkError, (Number(numberOfNetworkErrors) || 0) + 1));
	} else {
		const numberOfErrors: number = yield select(selectNumberOfResetPasswordError);
		yield put(setError(Errors.updatePasswordError, (Number(numberOfErrors) || 0) + 1));
	}
}

function* updatePasswordSuccessSaga() {
	yield put(setError(Errors.updatePasswordNetworkError, 0));
	yield put(setError(Errors.updatePasswordError, 0));
	yield put(clearErrors());
	yield put(clearSuccess());
	yield put(setSuccess(Success.snackbar, 'Easy peasy! Your password was successfully reset.'));
}

export default function* restorePasswordSaga() {
	yield all([
		takeEvery(C.UPDATE_PASSWORD_SUCCESS, updatePasswordSuccessSaga),
		takeEvery(C.UPDATE_PASSWORD_ERROR, updatePasswordErrorSaga)
	]);
}
