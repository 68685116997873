import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {IconButton, Layout} from 'src/components/@shared';
import {ReactComponent as BackIcon} from 'src/assets/backButton.svg';
import {ReactComponent as BanqLogoWhite} from 'src/assets/banqLogoWhite.svg';
import {ReactComponent as CloseButton} from 'src/assets/closeButton.svg';
import {Theme} from 'src/theme';
import {useDispatch} from 'react-redux';
import {logoutUser} from 'src/store/auth/actions';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		borderRadius: 0
	},
	header: {
		position: 'relative',
		background: theme.gradients.primary,
		height: 100,
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		paddingBottom: 20,
		marginBottom: 32
	},
	backIconButton: {
		opacity: 0.6,
		position: 'absolute',
		bottom: 25,
		left: 20
	},
	closeIconButton: {
		opacity: 0.6,
		position: 'absolute',
		bottom: 25,
		right: 25
	},
	content: {
		padding: '0 20px 50px 20px'
	}
}));

// Needs to be refactored violates SRP
interface Props {
	className?: string;
	children: ReactNode;
	noBackNavigation?: boolean;
	withoutCloseButton?: boolean;
	onBack?: () => void;
	onClose?: () => void;
}

export const MobileLayout = ({className, children, noBackNavigation, withoutCloseButton, onBack, onClose}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<Layout className={clsx(classes.root, className)}>
			<header className={classes.header}>
				{!noBackNavigation && (
					<IconButton className={classes.backIconButton} onClick={onBack}>
						<BackIcon />
					</IconButton>
				)}
				<BanqLogoWhite />
				{!withoutCloseButton && (
					<IconButton
						className={classes.closeIconButton}
						onClick={() => (onClose ? onClose() : dispatch(logoutUser()))}>
						<CloseButton />
					</IconButton>
				)}
			</header>
			<div className={classes.content}>{children}</div>
		</Layout>
	);
};
