import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {isMobile} from 'src/utils/isMobileDevice';
import {Typography, PopUp, Button} from 'src/components';
import {Theme} from 'src/theme';
import {trackPageView} from 'src/telemetry/ecommerceWidgetEvents';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	passwordInstruction: {
		paddingTop: 16
	},
	popupTitle: {margin: '50px auto 12px auto'},
	popupContent: {paddingTop: 12, textAlign: 'center', color: theme.colors.dustyGray},
	retryBtn: {marginTop: 24, background: theme.colors.purple, boxShadow: 'none'},
	closeBtn: {
		color: theme.colors.black,
		fontSize: 18,
		border: 'none',
		marginTop: 25,
		marginBottom: 63
	},
	bigBottomMargin: {
		marginBottom: 24
	},
	containedButton: {
		maxWidth: 320,
		background: theme.gradients.popUpButton,
		marginTop: 30
	}
}));

export enum PasswordErrorType {
	NetworkError = 'NetworkError',
	SomethingWentWrongError = 'Something went wrong error'
}

interface PasswordErrorPopUpProps {
	type?: PasswordErrorType;
	tries: number;
	onRetry: () => void;
	onClose: () => void;
}

export const PasswordErrorPopUp = ({type, tries, onRetry, onClose}: PasswordErrorPopUpProps) => {
	const classes = useStyles({isMobileDevice: isMobile});

	useEffect(() => {
		trackPageView({name: 'error pop up: We seem to be hitting an error'});
	}, []);

	if (!tries) return <></>;
	return (
		<PopUp onClose={onClose}>
			<Typography className={classes.popupTitle} size={24}>
				{type === PasswordErrorType.NetworkError ? 'Network Error' : 'Something went wrong'}
			</Typography>
			<Typography className={classes.popupContent} size={16} lineHeight={24}>
				{type === PasswordErrorType.NetworkError
					? 'Unable to create new password at this time.'
					: 'We seem to be hitting an error and are unable to create new password at this time. Please try again later.'}
			</Typography>
			{tries < 4 ? (
				<>
					<Button data-testid="retryBtn" fullWidth className={classes.retryBtn} onClick={onRetry}>
						Retry
					</Button>
					<Button data-testid="closeBtn" className={classes.closeBtn} variant="minimalistic" onClick={onClose}>
						Close
					</Button>
				</>
			) : (
				<Button
					onClick={onClose}
					variant="contained"
					fullWidth
					className={clsx(classes.bigBottomMargin, classes.containedButton)}>
					Close
				</Button>
			)}
		</PopUp>
	);
};
