import {makeStyles} from '@material-ui/styles';

import {Theme} from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
	content: {
		margin: '56px 0'
	},
	title: {
		marginBottom: 48,
		padding: '0 40px',
		textAlign: 'center',
		fontWeight: 500
	},
	subtitle: {
		marginTop: 32,
		fontWeight: 'bold'
	},
	list: {
		marginTop: 16,
		listStyleType: 'disc',
		'-webkit-padding-start': '24px'
	},
	listItem: {
		fontFamily: theme.fonts.primary,
		marginTop: 8
	},
	button: {
		marginTop: 14
	}
}));
