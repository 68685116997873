import axios, {AxiosError, AxiosResponse} from 'axios';
import {trackException} from 'src/telemetry/ecommerceWidgetEvents';
import {env} from 'src/config';

const http = axios.create({
	baseURL: `${env.apiBaseUrl}/v1`
});

export const logoutEvent = new Event('logout');

export const setAccessToken = (token: string) => {
	http.defaults.headers.common.Authorization = '';
	delete http.defaults.headers.common.Authorization;

	if (token) {
		http.defaults.headers.common.Authorization = `Bearer ${token}`;
	}
};

const requestParser = <T>(result: AxiosResponse<T>) => result.data;
const errorHandler = <T>(error: AxiosError<T>) => {
	if (error.response?.status === 401) return window.dispatchEvent(logoutEvent);
	trackException(new Error('Http error!'), error.response);
	return Promise.reject(error.response || 'Something went wrong');
};

export const setIdempotentToken = (token: string) => {
	http.defaults.headers.common['X-Idempotent-Id'] = token;
};

export const removeIdempotentHeader = () => {
	http.defaults.headers.common['X-Idempotent-Id'] = '';
	delete http.defaults.headers.common['X-Idempotent-Id'];
};

http.interceptors.response.use(requestParser, errorHandler);

http.interceptors.request.use((request) => request, errorHandler);

export default http;
