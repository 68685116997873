import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin, withAITracking} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from 'history';
import {ComponentType} from 'react';
import {env} from 'src/config';

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory();
const ai = new ApplicationInsights({
	config: {
		instrumentationKey: env.widgetAppInsightsKey,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: {history: browserHistory}
		}
	}
});

ai.loadAppInsights();

export function trackComponent<P>(
	component: ComponentType<P>,
	friendlyName: string,
	className?: string
): React.ComponentClass<P> {
	return withAITracking(reactPlugin, component, friendlyName, className);
}
export const {appInsights} = ai;
export {reactPlugin};
