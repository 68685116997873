import {parseUrl} from 'query-string';
import {env} from 'src/config';

const {query} = parseUrl(window.location.href);

const appConfig = query.appConfig
	? JSON.parse(String(query.appConfig))
	: {platformId: '00000000-0000-0000-0000-000000000000'};

export default {
	refreshToken: String(query.refreshToken || ''),
	origin: env.environment !== 'production' ? '*' : String(query.origin),
	appConfig,
	windowWidth: Number(query.windowWidth),
	windowHeight: Number(query.windowHeight),
	platformId: appConfig.platformId
};
