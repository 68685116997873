import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Colors, Theme} from 'src/theme';

interface StyleProps {
	background?: keyof Colors;
	blur?: Boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: ({background, blur}: StyleProps) => ({
		backgroundColor: (background && theme.colors[background]) || theme.colors.white,
		backdropFilter: blur && 'blur(8px)',
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: '0px',
		left: '0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 100
	})
}));

export const Backdrop = ({
	children,
	background,
	blur
}: {
	children: React.ReactNode;
	background?: keyof Colors;
	blur?: boolean;
}): JSX.Element => {
	const classes = useStyles({background, blur});
	return (
		<div data-testid="Backdrop" className={classes.root}>
			{children}
		</div>
	);
};
