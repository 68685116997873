import React from 'react';
import {appInsights} from '../telemetry/AppInsights';
import {HomeLayout} from './@shared';

export class ErrorBoundary extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {hasError: false};
	}

	componentDidCatch(error: Error, info: any) {
		// Display fallback UI
		this.setState({hasError: true});
		// You can also log the error to an error reporting service
		appInsights.trackException({exception: error}, info);
	}

	render() {
		const {children} = this.props;
		const {hasError} = this.state;

		if (hasError) {
			// You can render any custom fallback UI
			return <HomeLayout>Something went wrong.</HomeLayout>;
		}
		return children;
	}
}
