import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {IconButton} from 'src/components/@shared/IconButton/IconButton';
import {ReactComponent as EyeIconGray} from 'src/assets/eyeIconGray.svg';
import {ReactComponent as EyeIconBright} from 'src/assets/eyeIconBright.svg';
import {TextField, TextFieldProps} from 'src/components/@shared/TextField/TextField';

const useStyles = makeStyles(() => ({
	iconButton: {
		position: 'absolute',
		top: '50%',
		right: '0%'
	},
	formFieldLabelFilled: {
		display: `none`
	}
}));

export const PasswordField = ({fullWidth, label, error, helperText, ...props}: TextFieldProps) => {
	const [passwordShown, setPasswordShown] = useState(false);
	const classes = useStyles({passwordShown});

	const togglePasswordVisibility = () => {
		setPasswordShown(!passwordShown);
	};

	return (
		<TextField
			fullWidth
			label={label}
			type={passwordShown ? 'text' : 'password'}
			error={error}
			helperText={helperText}
			className={classes.formFieldLabelFilled}
			endAdornment={
				<IconButton className={classes.iconButton} onClick={togglePasswordVisibility}>
					{passwordShown ? <EyeIconBright data-testid="eyeIconBright" /> : <EyeIconGray data-testid="eyeIconGray" />}
				</IconButton>
			}
			{...props}
		/>
	);
};
