import React, {ChangeEvent, ComponentType, ElementType, useCallback, useState} from 'react';
import {getIn, useField, useFormikContext} from 'formik';

type ChangeHandler<V = string> = (value: V) => void;

export type FieldComponentProps<Props = Record<string, unknown>, Value = unknown> = Props & {
	error?: boolean;
	autoFocus?: boolean;
	helperText?: string;
	value: Value;
	onChange?: ChangeHandler<Value>;
	onBlur?: () => void;
};

type Props<P> = Omit<P, 'name' | 'value' | 'component'> & {
	name: string;
	component: ComponentType<P>;
	helperText?: unknown;
	onChange?: (value: unknown) => void;
	shouldTrimText?: boolean;
	error?: boolean;
};

type FieldFC = <P>(props: Props<P>) => JSX.Element;

export const Field: FieldFC = ({name, component, helperText, error, shouldTrimText, onChange, ...props}) => {
	const form = useFormikContext();
	const [field, meta, helpers] = useField<string>(name);
	const [submitCount, setSubmitCount] = useState(0);

	const isChangesWasNotMadeAfterSubmit = form.submitCount > submitCount;
	const initialError = (isChangesWasNotMadeAfterSubmit && getIn(form.initialErrors, name)) || (error && helperText);

	const Component: ElementType = component;

	const handleChange: ChangeHandler<unknown> = useCallback(
		(value): void => {
			const event = value as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
			const val = shouldTrimText ? (event?.target?.value ?? value)?.trim() : event?.target?.value ?? value;
			helpers.setValue(val);
			onChange?.(val);
			/*
			 We need setTimeout because we want state updates out of stack.
			 Because react would throw warnings that state was not updated properly
			 */
			setTimeout(() => setSubmitCount(form.submitCount));
		},
		[form.submitCount, helpers, onChange, shouldTrimText]
	);

	return (
		<Component
			{...field}
			{...props}
			error={Boolean((meta.error && meta.touched) || initialError)}
			helperText={(meta.touched && meta.error) || initialError || helperText}
			onChange={handleChange}
		/>
	);
};
