import {C, Errors, Loadings, Success} from 'src/store/constants';
import {Action, LoadAction, PayloadAction} from 'src/store/types';
import {LoginUserRequest, AuthenticatedResponse} from 'src/api/auth';
import api, {MeResponse} from 'src/api';

export type RestoreAuthSessionAction = LoadAction<C.RESTORE_AUTH_SESSION>;
export type RestoreAuthSessionSuccessAction = PayloadAction<C.RESTORE_AUTH_SESSION_SUCCESS, AuthenticatedResponse>;
export type RestoreAuthSessionCompleteAction = Action<C.RESTORE_AUTH_SESSION_COMPLETE>;
export type LoginUserAction = LoadAction<C.LOGIN_USER>;
export type LoginUserSuccessAction = PayloadAction<C.LOGIN_USER_SUCCESS, AuthenticatedResponse>;
export type LoginUserErrorAction = PayloadAction<C.LOGIN_USER_ERROR, any>;
export type LogoutUserAction = Action<C.LOGOUT_USER>;
export type LogoutUserSuccessAction = Action<C.LOGOUT_USER_SUCCESS>;
export type LoadAuthUserAction = LoadAction<C.LOAD_AUTH_USER>;
export type LoadAuthUserSuccessAction = PayloadAction<C.LOAD_AUTH_USER_SUCCESS, MeResponse>;

export const restoreAuthSession = (refreshToken: string): RestoreAuthSessionAction => ({
	type: C.RESTORE_AUTH_SESSION,
	load: api.auth.restoreSession(refreshToken),
	loading: Loadings.restoreAuthSessionLoading,
	error: Errors.loginUserError,
	success: Success.isRestoredAuthSession
});

export const loginUser = ({email, password}: LoginUserRequest): LoginUserAction => ({
	type: C.LOGIN_USER,
	load: api.auth.login({email, password}),
	loading: Loadings.loginUser,
	error: Errors.loginUserError,
	success: Success.isLoginUserSuccessful
});

export const logoutUser = (): LogoutUserAction => ({
	type: C.LOGOUT_USER
});
