import {all, put, takeEvery, delay} from 'redux-saga/effects';
import {sendPostMessage} from 'src/utils';
import {PostMessageTypes} from 'src/types';
import {setAccessToken} from 'src/api/http';
import {trackEvent} from 'src/telemetry/ecommerceWidgetEvents';
import {removeLoading, setLoading} from 'src/store/@loadings/actions';
import {setError} from 'src/store/@errors/actions';
import {C, Errors, Loadings} from '../constants';
import {
	LoginUserErrorAction,
	LoginUserSuccessAction,
	restoreAuthSession,
	RestoreAuthSessionSuccessAction
} from './actions';

const closeWidget = () => {
	sendPostMessage(PostMessageTypes.close);
	trackEvent('closed widget');
};

const invalidateSession = () => {
	sendPostMessage(PostMessageTypes.login, {refreshToken: ''});
};

function* logoutSaga() {
	invalidateSession();
	yield put(setLoading(Loadings.logoutUserLoading));
	yield delay(500);
	yield put(removeLoading(Loadings.logoutUserLoading));
	yield put({type: C.LOGOUT_USER_SUCCESS});
}

function* loginUserErrorAction({payload: {error}}: LoginUserErrorAction) {
	yield put(setError(Errors.loginUserError, error));
	yield put(setError(Errors.snackbar, 'There was an error during authorization'));
	trackEvent('login user error', {snackbar: 'There was an error during authorization'});
}

function* loginUserSuccessSaga({payload}: LoginUserSuccessAction) {
	setAccessToken(payload.access_token);
	sendPostMessage(PostMessageTypes.login, {refreshToken: payload.refresh_token});
	const expiresInMs = payload.expires_in * 1000;
	const MINUTE = 60000; // milliseconds
	yield delay(expiresInMs - MINUTE);
	yield put(restoreAuthSession(payload.refresh_token));
}

function* restoreAuthSessionSuccessSaga({payload}: RestoreAuthSessionSuccessAction) {
	yield put({type: C.LOGIN_USER_SUCCESS, payload});
	setAccessToken(payload.access_token);
	yield put({type: C.RESTORE_AUTH_SESSION_COMPLETE});
	yield put(removeLoading(Loadings.loginUser));
}

function* restoreAuthSessionErrorSaga() {
	invalidateSession();
	yield put(removeLoading(Loadings.loginUser));
	yield put({type: C.RESTORE_AUTH_SESSION_COMPLETE});
}

export default function* authSaga() {
	yield all([
		takeEvery(C.LOGIN_USER_ERROR, loginUserErrorAction),
		takeEvery(C.LOGIN_USER_SUCCESS, loginUserSuccessSaga),
		takeEvery(C.RESTORE_AUTH_SESSION_SUCCESS, restoreAuthSessionSuccessSaga),
		takeEvery(C.RESTORE_AUTH_SESSION_ERROR, restoreAuthSessionErrorSaga),
		takeEvery(C.LOGOUT_USER, logoutSaga),
		takeEvery(C.LOGOUT_USER_SUCCESS, closeWidget)
	]);
}
