import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as CheckIcon} from 'src/assets/images/check.svg';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	root: {
		height: 20,
		position: 'relative'
	},
	rootInput: ({checked}: {checked: boolean}) => ({
		appearance: 'none',
		display: 'inline-block',
		visibility: checked ? 'hidden' : 'visible',
		width: 20,
		height: 20,
		marginTop: 0,
		border: '1px solid #B3B3B3',
		backgroundColor: 'transparent',
		borderRadius: '50%',

		'& + label': {
			visibility: 'hidden',
			position: 'absolute',
			left: 5
		},
		'&:checked + label': {
			visibility: 'visible',
			position: 'absolute',
			left: 5
		}
	}),
	inputLabel: {
		display: 'inline-block'
	}
}));

export type RadioButtonsProps = {
	id: string;
	name: string;
	value: string | number;
	checked?: boolean;
	className?: string;
	onClick?: () => void;
};

export const RadioButton = ({id, name, value, checked, onClick, className}: RadioButtonsProps) => {
	const classes = useStyles({checked: checked || false});
	return (
		<div className={clsx(classes.root, className)}>
			<input
				className={classes.rootInput}
				type="radio"
				id={id}
				name={name}
				value={value}
				defaultChecked={checked}
				onClick={onClick}
			/>
			{/* eslint-disable jsx-a11y/label-has-associated-control */}
			<label className={classes.inputLabel} htmlFor="radio-group">
				<span>
					<CheckIcon />
				</span>
			</label>
		</div>
	);
};
