export enum PostMessageTypes {
	styles = 'styles',
	redirect = 'redirect',
	close = 'close',
	login = 'login',
	successPayment = 'successPayment',
	settings = 'settings'
}

export type PostMessage<P> = {
	type: PostMessageTypes;
	payload: P;
};
