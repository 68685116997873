import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: 0,
		transform: 'translateY(-10px)',
		transition: 'all 0.2s ease',
		opacity: 0,
		height: 0,
		overflow: 'hidden'
	},
	visible: {
		padding: '18px 0',
		opacity: 1,
		transform: 'translateY(0px)',
		height: 'auto'
	}
}));
