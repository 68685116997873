import {Action} from 'redux';
import {C} from '../constants';
import {PayloadAction} from '../types';

export type SetErrorAction = PayloadAction<C.SET_ERROR, {key: string; error: unknown}>;
export type RemoveErrorAction = PayloadAction<C.REMOVE_ERROR, string>;
export type ClearErrorsAction = Action<C.CLEAR_ERRORS>;

export const setError = (key: string, error: unknown = true): SetErrorAction => ({
	type: C.SET_ERROR,
	payload: {key, error}
});

export const removeError = (key: string): RemoveErrorAction => ({
	type: C.REMOVE_ERROR,
	payload: key
});

export const clearErrors = (): ClearErrorsAction => ({
	type: C.CLEAR_ERRORS
});
