import {iframe} from 'src/config';
import {Loadings, Success} from '../constants';
import {State} from '../reducer';

export const selectLoginUserLoading = (state: State) => state.loadings[Loadings.loginUser];

export const selectIsAuthenticated = (state: State) => Boolean(state.auth.accessToken);

export const selectAuthUser = (state: State) => state.auth.user;

export const selectIsAuthChecked = (state: State) => !iframe.refreshToken || state.auth.isAuthChecked;

export const selectIsLoginError = (state: State) => Boolean(state.errors[Loadings.loginUser]);

export const selectIsDemoUserBrandNew = (state: State) => Boolean(state.auth?.isDemoUserBrandNew);

export const selectIsAuthUserLoaded = (state: State) => Boolean(state.messages[Success.isAuthUserLoadedSuccessfully]);

export const selectIsNewAuthUser = (state: State) => Boolean(state.messages[Success.isNewAuthUser]);
