import React, {HTMLAttributes} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {Theme} from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
	link: {
		color: theme.colors.purple,
		fontSize: 12,
		borderBottom: `0.5px solid ${theme.colors.purple}`,
		cursor: 'pointer',
		textDecoration: 'none',
		fontWeight: 600
	}
}));

interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
	to: string;
}

export const Link = ({className, to, children}: LinkProps) => {
	const classes = useStyles();
	return (
		<a className={clsx(classes.link, className)} href={to} target="_blank" rel="noreferrer">
			{children}
		</a>
	);
};
