import {useEffect} from 'react';

export const useScript = (src: string | undefined, id?: string): void =>
	useEffect(() => {
		if (!src) return () => {};
		const script = document.createElement('script');
		script.src = src;
		if (id) script.id = id;
		script.async = true;
		document.body.append(script);
		return () => {
			document.body.append(script);
		};
	}, [id, src]);
