import {useSelector} from 'react-redux';
import {Errors} from 'src/store/constants';
import {setError} from 'src/store/@errors/actions';
import {selectIsAnyFromSomethingWentWrongErrorsList} from 'src/store/@errors/selectors';

export const useSetSomethingWentWrongMessage = () => {
	const shouldShowSomethingWentWrongMessage = useSelector(selectIsAnyFromSomethingWentWrongErrorsList);

	if (shouldShowSomethingWentWrongMessage) {
		setError(Errors.snackbar, 'Something went wrong');
	}
};
