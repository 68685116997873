import {auth as config, env} from 'src/config';
import http from './http';

export type LoginUserRequest = {email: string; password: string};

export type AuthenticatedResponse = {
	access_token: string;
	refresh_token: string;
	expires_in: number;
	token_type: string;
};

const auth = {
	login({email, password}: LoginUserRequest): Promise<AuthenticatedResponse> {
		return http.post(`https://${env.auth0Domain}/oauth/token`, {
			...config,
			grant_type: 'password',
			username: email,
			password
		});
	},
	restoreSession(refresh_token: string): Promise<AuthenticatedResponse> {
		return http.post(`https://${env.auth0Domain}/oauth/token`, {
			...config,
			grant_type: 'refresh_token',
			refresh_token
		});
	}
};

export default auth;
