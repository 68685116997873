import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from '@reach/router';
import {isMobile, sendPostMessage} from 'src/utils';
import {selectIsAuthenticated} from 'src/store/auth/selectors';
import {getPlatformInfo} from 'src/store/integrator/actions';
import {selectIsPlatformInfoLoading, selectIsPlatformInfoLoaded} from 'src/store/integrator/selectors';
import {PostMessageTypes} from 'src/types';
import {HomeLayout, Typography, ViewTitle} from 'src/components/@shared';
import {LoginContainer} from 'src/components/login/LoginContainer';
import {iframe} from 'src/config';

export const LoginView = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isPlatformInfoLoading = useSelector(selectIsPlatformInfoLoading);
	const isPlatformInfoLoaded = useSelector(selectIsPlatformInfoLoaded);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const platformId = iframe?.platformId;

	if (!platformId) throw new Error('No platformId in config');

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(getPlatformInfo(platformId));
		}
	}, [dispatch, isAuthenticated, platformId]);

	useEffect(() => {
		if (isAuthenticated && isPlatformInfoLoaded) {
			sendPostMessage(PostMessageTypes.settings);
			navigate('/');
		}
	}, [dispatch, isAuthenticated, isPlatformInfoLoaded, navigate]);

	return (
		<HomeLayout>
			<ViewTitle>Connect to banq</ViewTitle>
			<Typography size={16} lineHeight={isMobile ? 24 : 20}>
				Log in to your NFT wallet.
			</Typography>
			<LoginContainer isLoading={isPlatformInfoLoading} />
		</HomeLayout>
	);
};
