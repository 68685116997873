import React from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {isMobile} from 'src/utils/isMobileDevice';
import {Field, Button, Form, Spinner, PhoneField} from 'src/components/@shared';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
	root: {
		padding: '20px 0 0'
	},
	input: {
		margin: 24
	},
	phoneInput: {
		marginTop: 15
	},
	nextBtn: {marginTop: 96},
	mobileButton: {
		position: 'absolute',
		bottom: 50,
		width: '90%'
	}
}));

const validationSchema = yup.object().shape({
	phone: yup
		.string()
		.trim('The contact name cannot include leading and trailing spaces')
		.min(10, 'Phone must be at least 10 characters')
		.max(14, 'Phone must be at most 14 characters')
		.required('Phone is a required field')
});

const initialValues = {
	phone: ''
};

export type MobilePhoneFormValues = {
	phone: string;
};

interface Props {
	isLoading?: boolean;
	isPhoneVerificationError?: boolean;
	onSubmit: (values: MobilePhoneFormValues) => void;
}

export const MobilePhoneForm = ({isLoading, isPhoneVerificationError, onSubmit}: Props) => {
	const classes = useStyles({isMobileDevice: isMobile});

	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			initialErrors={isPhoneVerificationError ? {phone: 'Number not found in our database.'} : undefined}
			className={classes.root}>
			<Field
				data-testid="phone"
				name="phone"
				label="Enter Mobile Number"
				className={classes.phoneInput}
				component={PhoneField}
				helperText="We'll send you a text to verify your phone number."
			/>
			<Button
				disabled={isLoading}
				fullWidth
				type="submit"
				className={clsx({[classes.mobileButton]: isMobile, [classes.nextBtn]: !isMobile})}>
				{isLoading ? <Spinner color="mercury" size={19} /> : 'Next'}
			</Button>
		</Form>
	);
};
