import {C, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api, {LinkingPermissionsResponse} from 'src/api';

export type ConfirmAccessAction = LoadAction<C.CONFIRM_ACCESS>;

export type ConfirmAccessActionSuccessAction = PayloadAction<C.CONFIRM_ACCESS_SUCCESS, LinkingPermissionsResponse>;

export const givePermissions = (platformId: string): ConfirmAccessAction => ({
	type: C.CONFIRM_ACCESS,
	load: api.linkingPermissions.givePermissions(platformId),
	loading: Loadings.confirmAccessLoading,
	error: Errors.confirmAccessError,
	success: Success.confirmAccessSuccess
});
