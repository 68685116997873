import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from '@reach/router';
import {clearErrors} from 'src/store/@errors/actions';
import {routes} from 'src/config';
import {isMobile} from 'src/utils/isMobileDevice';
import {CreateNewPasswordForm, HomeLayout, NavigateIconButton, Typography, ViewTitle} from 'src/components';
import {logoutUser} from 'src/store/auth/actions';
import {
	selectIsUpdatePasswordSuccessful,
	selectNumberOfResetPasswordError,
	selectNumberOfResetPasswordNetworkError
} from 'src/store/restorePassword/selectors';
import {trackEvent, trackPageView} from 'src/telemetry/ecommerceWidgetEvents';
import {updatePassword} from 'src/store/restorePassword/actions';
import {clearSuccess, removeSuccess} from 'src/store/@success/actions';
import {Success} from 'src/store/constants';
import {PasswordErrorPopUp, PasswordErrorType} from './PasswordErrorPopUp';

const useStyles = makeStyles(() => ({
	passwordInstruction: {
		paddingTop: 16
	}
}));

interface RouterState {
	phone: string;
	verificationCode: string;
}

export const CreateNewPasswordView = () => {
	const classes = useStyles({isMobileDevice: isMobile});
	const {phone, verificationCode} = useLocation().state as RouterState;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isUpdatePasswordSuccessful = useSelector(selectIsUpdatePasswordSuccessful);
	const numberOfErrors = useSelector(selectNumberOfResetPasswordError);
	const numberOfNetworkErrors = useSelector(selectNumberOfResetPasswordNetworkError);
	const [passwordState, setPasswordState] = useState<string>('');

	const navigateBack = () => {
		dispatch(clearErrors());
		dispatch(clearSuccess());
		trackEvent('click: navigate back on create new password view');
		navigate(routes.restorePasswordVerificationCode);
	};

	useEffect(() => {
		if (isUpdatePasswordSuccessful) {
			trackEvent(`update password successful`);
			navigate(routes.login);
			dispatch(removeSuccess(Success.isUpdatePasswordSuccessful));
		}
	}, [isUpdatePasswordSuccessful, navigate, dispatch]);

	const handleSubmit = ({password}: {password: string}) => {
		setPasswordState(password);
		trackEvent(`click: reset password: "Done"`);
		dispatch(updatePassword({phone, verificationCode, password}));
	};

	useEffect(() => {
		trackPageView({name: 'create new password view'});
	}, []);

	return (
		<HomeLayout onBack={navigateBack}>
			<ViewTitle startAdornment={<NavigateIconButton variant="back" onClick={navigateBack} />}>
				Reset Password
			</ViewTitle>
			<Typography size={18}>Create new password</Typography>
			<Typography className={classes.passwordInstruction} size={16}>
				Password must be between 8 and 70 characters.
			</Typography>
			<CreateNewPasswordForm onSubmit={handleSubmit} />
			<PasswordErrorPopUp
				type={numberOfNetworkErrors ? PasswordErrorType.NetworkError : PasswordErrorType.SomethingWentWrongError}
				tries={Number(numberOfNetworkErrors) || Number(numberOfErrors && 1) || 0}
				onRetry={() => {
					trackEvent(`reset password: retry update password`);
					handleSubmit({password: passwordState});
				}}
				onClose={() => {
					trackEvent(`close widget on createNewPasswordView`);
					dispatch(logoutUser());
				}}
			/>
		</HomeLayout>
	);
};
