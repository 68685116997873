import React, {ReactNode} from 'react';
import clsx from 'clsx';

import {Typography} from 'src/components';
import {Colors} from 'src/theme';
import {useStyles} from './ErrorMessage.styles';

interface Props {
	className?: string;
	children: ReactNode;
	visible?: boolean;
	color?: keyof Colors;
}

export const ErrorMessage = ({className, visible = true, children, color = 'red'}: Props) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className, {[classes.visible]: visible})}>
			<Typography color={color}>{children}</Typography>
		</div>
	);
};
