import {C} from 'src/store/constants';
import {ConfirmAccessActionSuccessAction} from './actions';

type ConfirmAccess = {
	status?: number;
	msg?: string;
};

const initialState: ConfirmAccess = {};

const confirmAccessReducer = (state = initialState, action: ConfirmAccessActionSuccessAction) => {
	switch (action.type) {
		case C.CONFIRM_ACCESS_SUCCESS:
			return action.payload.data;

		default:
			return state;
	}
};

export default confirmAccessReducer;
