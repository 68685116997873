import React, {useEffect, useState} from 'react';
import {useNavigate} from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';

import {Typography, Button, HomeLayout, ViewTitle, ErrorDialog} from 'src/components/@shared';
import {LoaderWithBackdrop} from 'src/components/globalLoading';

import {iframe, routes} from 'src/config';
import {logoutUser} from 'src/store/auth/actions';
import {selectPlatformBusinessName} from 'src/store/integrator/selectors';
import {givePermissions} from 'src/store/accessСonfirmation/actions';
import {selectConfirmAccessStatus} from 'src/store/accessСonfirmation/selectors';
import {createErrorSelector} from 'src/store/@errors/selectors';
import {selectIsLoading} from 'src/store/@loadings/selectors';
import {Errors} from 'src/store/constants';
import {useStyles} from './DetailsView.styles';

export const DetailsView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const marketplace = useSelector(selectPlatformBusinessName);
	const accessStatus = useSelector(selectConfirmAccessStatus);
	const isLoading = useSelector(selectIsLoading);
	const isError = Boolean(useSelector(createErrorSelector(Errors.confirmAccessError)));
	const [retryCount, setRetryCount] = useState(0);

	const classes = useStyles();

	const title = `Give ${marketplace} permission to access your banq wallet?`;

	useEffect(() => {
		if (accessStatus === 204) {
			navigate(routes.successScreen);
		}
		if (accessStatus === 409) {
			navigate(routes.alreadyLinkedScreen);
		}
	}, [accessStatus, navigate]);

	const handleAllow = () => {
		setRetryCount((prevCount) => prevCount + 1);
		dispatch(givePermissions(iframe.platformId));
	};

	const handleClose = () => dispatch(logoutUser());

	return (
		<HomeLayout>
			<LoaderWithBackdrop isLoading={isLoading} />
			{isError && <ErrorDialog onRetry={handleAllow} onClose={handleClose} count={retryCount} />}
			<div className={classes.content}>
				<ViewTitle className={classes.title}>{title}</ViewTitle>
				<Typography size={18} lineHeight={24} className={classes.subtitle}>
					{marketplace} can view:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>The NFTs in your wallet</li>
					<li className={classes.listItem}>Your wallet handle</li>
				</ul>
				<Typography size={18} lineHeight={24} className={classes.subtitle}>
					{marketplace} will be able to:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>Buy and sell NFTs on your behalf with your permission</li>
				</ul>
			</div>
			<div>
				<Button className={classes.button} fullWidth onClick={handleAllow}>
					Allow
				</Button>
				<Button className={classes.button} fullWidth variant="outlined" onClick={handleClose}>
					Deny
				</Button>
			</div>
		</HomeLayout>
	);
};
