import {State} from '../reducer';
import {Errors, Success} from '../constants';

export const selectIsUpdatePasswordSuccessful = (state: State) => state.messages[Success.isUpdatePasswordSuccessful];

export const selectIsUpdatePasswordError = (state: State) => Boolean(state.errors[Errors.updatePasswordError]);

export const selectNumberOfResetPasswordError = (state: State) => state.errors[Errors.updatePasswordError];

export const selectNumberOfResetPasswordNetworkError = (state: State) =>
	state.errors[Errors.updatePasswordNetworkError];
