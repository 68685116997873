import React, {ComponentType, useState} from 'react';
import Select, {
	components,
	ControlProps,
	OptionProps,
	DropdownIndicatorProps,
	GroupBase,
	SingleValue,
	StylesConfig
} from 'react-select';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';

import {DropdownIndicator} from 'src/components/@shared/Dropdown/DropdownIndicator';

import theme from 'src/theme';
import {OptionI} from 'src/components/@shared/Dropdown/types/index';

const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column'
	},
	formFieldLabel: {
		display: 'block',
		fontSize: 16,
		fontFamily: 'Sofia Pro',
		color: theme.colors.black50,
		margin: 0,
		paddingTop: 18,
		position: 'absolute',
		top: 0,
		transition: 'all 0.4s'
	},
	formFieldLabelActive: {
		fontSize: '0.75rem',
		padding: 0,
		transition: 'all 0.4s'
	},
	formFieldLabelFocused: {
		color: theme.colors.purple
	},
	formFieldLabelFilled: {
		fontSize: '0.75rem',
		padding: 0,
		transition: 'all 0.4s'
	},
	caretIconNonActive: {
		transform: 'rotate(180deg)',
		'& > path': {
			fill: theme.colors.black50
		}
	},
	option: {
		textAlign: 'left',
		alignItems: 'center',
		display: 'flex',
		color: theme.colors.black,
		cursor: 'pointer',
		fontFamily: 'Sofia Pro',
		padding: 18,
		fontSize: 16,
		fontWidth: 400,
		backgroundColor: 'transparent',
		'&:hover': {
			background: theme.colors.lightPurple
		}
	},
	optionActive: {
		background: theme.colors.lightPurple
	},
	customControl: {
		display: 'flex',
		width: '100%',
		alignItems: 'center'
	},
	icon: {
		height: 21,
		width: 32,
		marginRight: 15,
		borderRadius: 4
	},
	iconCustom: {
		height: 32
	},
	endAdorment: {
		justifySelf: 'end',
		color: theme.colors.black50,
		marginLeft: 'auto'
	},
	error: {
		color: theme.colors.red,
		fontSize: 12,
		fontFamily: 'Sofia Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		lineHeight: '16px'
	}
}));

const getSelectStyles = (name: string | undefined) => ({
	control: (provided: React.CSSProperties, state: {isSelected: boolean; menuIsOpen: boolean}) => ({
		...provided,
		display: 'flex',
		border: 0,
		color: theme.colors.black,
		background: 'transparent',
		boxShadow: 'none',
		borderBottom: `1px solid ${state.menuIsOpen ? theme.colors.purple : theme.colors.black20})
		}`,
		fontSize: 16,
		fontFamily: theme.fonts.primary,
		borderRadius: 0,
		'&:hover': {
			cursor: 'pointer'
		},
		transition: 'all 0.4s',
		padding: '22px 0 4px 0',
		minHeight: 'none'
	}),
	input: (provided: React.CSSProperties) => ({
		...provided,
		fontWeight: 400,
		margin: 0,
		padding: 0,
		color: theme.colors.black
	}),
	indicatorSeparator: (provided: React.CSSProperties) => ({
		...provided,
		display: 'none'
	}),
	menu: (provided: React.CSSProperties) => ({
		...provided,
		top: 52,
		boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15)',
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		minWidth: 192,
		marginTop: name === 'state' ? '-2px' : '8px'
	}),
	menuList: (provided: React.CSSProperties) => ({
		'&': {
			...provided,
			maxHeight: 260,
			padding: 0
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: theme.colors.purple15
		},
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: theme.colors.purple15
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.purple
		}
	}),
	indicatorsContainer: (provided: React.CSSProperties) => ({
		'& div': {
			...provided,
			padding: 0
		},
		'& div:first-child': {
			display: 'none'
		}
	}),
	valueContainer: (provided: React.CSSProperties) => ({
		...provided,
		padding: 0
	}),
	singleValue: (provided: React.CSSProperties) => ({
		...provided,
		color: theme.colors.black
	}),
	noOptionsMessage: (provided: React.CSSProperties) => ({
		...provided,
		cursor: 'pointer',
		fontFamily: 'Sofia Pro',
		padding: 18,
		fontSize: 16,
		fontWidth: 400,
		color: theme.colors.black50
	})
});

export interface DropdownI {
	onChange: (newValue: SingleValue<OptionI>) => void;
	label?: string;
	name?: string;
	className?: string;
	options: Array<OptionI>;
	value?: OptionI | null;
	placeholder?: string;
	isDisabled?: boolean;
	styles?: StylesConfig<OptionI, false, GroupBase<OptionI>> | undefined;
	hasEndAdorment?: boolean;
	error?: boolean;
}

export const Dropdown = ({
	className,
	label,
	onChange,
	options,
	value,
	name,
	styles,
	placeholder,
	isDisabled,
	hasEndAdorment,
	error
}: DropdownI): JSX.Element => {
	const [formIsActive, setFormIsActive] = useState(false);
	const [formIsFilled, setFormIsFilled] = useState(false);
	const [focused, setFocused] = useState<boolean | null>(null);
	const [formIsAutoFilled, setFormIsAutoFilled] = useState(false);
	const classes = useStyles();

	const setActive = (elValue: string, active: boolean): void => {
		if (active || elValue) {
			setFormIsActive(true);
			return;
		}
		if (formIsAutoFilled && !elValue) {
			setFormIsAutoFilled(false);
		}
		setFocused(false);
		setFormIsActive(false);
		setFormIsFilled(Boolean(elValue));
	};

	const getIndicator = (params: React.PropsWithChildren<DropdownIndicatorProps<OptionI, false, GroupBase<OptionI>>>) =>
		DropdownIndicator({defaultProps: params, className: focused ? '' : classes.caretIconNonActive});

	const getOption = (params: OptionProps<OptionI>) => {
		const optionClass = clsx(
			classes.option,
			'needsclick',
			(params.isSelected || params.isFocused) && classes.optionActive
		);
		// temporary. TODO: remove library and add all flags as svg at /images
		const iconClass = clsx(
			classes.icon,
			(params.data.value === 'US' || params.data.value === 'UM' || params.data.value === 'GB') && classes.iconCustom
		);
		return (
			<div
				role="menuitem"
				tabIndex={0}
				className={optionClass}
				onKeyPress={params.innerProps.onKeyPress}
				onClick={params.innerProps.onClick}>
				{params.data.icon && <img className={iconClass} src={params.data.icon} alt={params.data.value} />}
				<span>{params.data.label}</span>
				{hasEndAdorment && <div className={classes.endAdorment}>{params.data.value}</div>}
			</div>
		);
	};

	const getControl = (params: ControlProps<OptionI, false>) => {
		// temporary. TODO: remove library and add all flags as svg at /images
		const iconClass = clsx(
			classes.icon,
			(value?.value === 'US' || value?.value === 'UM' || value?.value === 'GB') && classes.iconCustom
		);
		return (
			<components.Control {...params} className={clsx(classes.customControl, 'needsclick')}>
				{value?.icon && <img className={iconClass} src={value?.icon} alt={value?.value} />}
				{params.children}
			</components.Control>
		);
	};

	return (
		<div className={clsx(classes.wrapper, className)}>
			<label
				htmlFor={label}
				className={clsx({
					[classes.formFieldLabel]: true,
					[classes.formFieldLabelActive]: formIsActive || Boolean(value),
					[classes.formFieldLabelFilled]: formIsFilled || formIsAutoFilled,
					[classes.formFieldLabelFocused]: focused
				})}>
				{label}
			</label>
			<Select
				components={{
					DropdownIndicator: getIndicator,
					Option: getOption as ComponentType<OptionProps<OptionI, false, GroupBase<OptionI>>>,
					Control: getControl
				}}
				styles={
					styles || (getSelectStyles(name) as unknown as StylesConfig<OptionI, false, GroupBase<OptionI>> | undefined)
				}
				options={options}
				name={name}
				value={value}
				onChange={(event) => {
					setFocused(false);
					onChange(event);
				}}
				isClearable
				onBlur={(event) => {
					event.preventDefault();
					setActive(event?.target?.value, false);
				}}
				onFocus={(event) => {
					// we need this to set cursor after the last character in the field
					event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length);
					setActive(event?.target?.value, true);
					setFocused(true);
				}}
				placeholder={placeholder || ''}
				isDisabled={isDisabled}
				noOptionsMessage={() => 'No Options'}
			/>
			{error && <p className={classes.error}>This field is required</p>}
		</div>
	);
};
