export default {
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	bootstrapCardUrl: process.env.REACT_APP_BOOTSTRAP_CARD_URL as string,
	environment: process.env.REACT_APP_ENVIRONMENT as string,
	auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
	apiBaseUrl: process.env.REACT_APP_BANQ_API_BASE_URL as string,
	plaidClientName: process.env.REACT_APP_PLAID_CLIENT_NAME as string,
	plaidPublicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY as string,
	plaidPublicEnv: process.env.REACT_APP_PLAID_PUBLIC_ENV as string,
	plaidProduct: process.env.REACT_APP_PLAID_PRODUCT as string,
	plaidLanguage: process.env.REACT_APP_PLAID_LANGUAGE as string,
	plaidDashboardConfigName: process.env.REACT_APP_PLAID_DASHBOARD_CONFIG_NAME as string,
	widgetAppInsightsKey: process.env.REACT_APP_WIDGET_APPINSIGHTS_KEY as string,
	segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY as string
};
