import {C, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api from 'src/api';

export type UpdatePasswordActionParams = {
	phone: string;
	password: string;
	verificationCode: string;
};

export type UpdatePasswordAction = LoadAction<C.UPDATE_PASSWORD>;
export type UpdatePasswordSuccessAction = PayloadAction<C.UPDATE_PASSWORD_SUCCESS, string>;
export type UpdatePasswordErrorAction = PayloadAction<C.UPDATE_PASSWORD_ERROR, any>;

export const updatePassword = ({
	phone,
	verificationCode,
	password
}: UpdatePasswordActionParams): UpdatePasswordAction => ({
	type: C.UPDATE_PASSWORD,
	load: api.phoneVerification.updatePassword({phone, verificationCode, password}),
	loading: Loadings.updatePasswordLoading,
	error: Errors.updatePasswordError,
	success: Success.isUpdatePasswordSuccessful
});
