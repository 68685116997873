import React, {createContext, useContext} from 'react';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {reactPlugin} from './AppInsights';

const AppInsightsContext = createContext<ReactPlugin>(reactPlugin);

const AppInsightsContextProvider: React.FC = ({children}) => (
	<AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
);

const useAppInsightsContext = (): ReactPlugin => useContext(AppInsightsContext);

export {AppInsightsContext, AppInsightsContextProvider, useAppInsightsContext};
