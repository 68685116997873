import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import {createHistory, createMemorySource, LocationProvider} from '@reach/router';
import reportWebVitals from './reportWebVitals';
import store from './store';
import App from './App';
import theme from './theme';
import './index.css';
import {LoaderWithBackdrop, SnackbarMessage} from './components';
import {AppInsightsContextProvider} from './telemetry/AppInsightsContext';
import {ErrorBoundary} from './components/ErrorBoundary';

ReactDOM.render(
	<React.StrictMode>
		<AppInsightsContextProvider>
			<LocationProvider history={createHistory(createMemorySource('/'))}>
				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<ErrorBoundary>
							<App />
						</ErrorBoundary>
						<SnackbarMessage />
						<LoaderWithBackdrop />
					</Provider>
				</ThemeProvider>
			</LocationProvider>
		</AppInsightsContextProvider>
	</React.StrictMode>,
	document.querySelector('#root')
);

reportWebVitals();
