import React, {useEffect} from 'react';
import {useNavigate} from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';
import {clearErrors} from 'src/store/@errors/actions';
import {clearSuccess} from 'src/store/@success/actions';
import {routes} from 'src/config';
import {isMobile} from 'src/utils/isMobileDevice';
import {
	HomeLayout,
	ViewTitle,
	NavigateIconButton,
	Typography,
	MobilePhoneForm,
	MobilePhoneFormValues
} from 'src/components';
import {sendVerificationCode} from 'src/store/phoneVerification/actions';
import {
	selectIsSendVerificationCodeError,
	selectIsSendVerificationCodeLoading,
	selectIsSendVerificationCodeSuccess
} from 'src/store/phoneVerification/selectors';
import {trackEvent, trackPageView} from 'src/telemetry/ecommerceWidgetEvents';

export const RestorePasswordView = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isVerificationCodeError = useSelector(selectIsSendVerificationCodeError);
	const isVerificationCodeSending = useSelector(selectIsSendVerificationCodeLoading);
	const isCodeVerificationSuccessful = useSelector(selectIsSendVerificationCodeSuccess);

	const navigateBack = () => {
		trackEvent('click: restore password view: "Back button"');
		dispatch(clearErrors());
		dispatch(clearSuccess());
		navigate(routes.login);
	};

	useEffect(() => {
		if (isCodeVerificationSuccessful) navigate(routes.restorePasswordVerificationCode);
	}, [isCodeVerificationSuccessful, navigate]);

	const handleSubmit = ({phone}: MobilePhoneFormValues): void => {
		dispatch(clearErrors());
		dispatch(sendVerificationCode(phone, 'resetPassword'));
		trackEvent(`click: reset password: "Next"`);
	};

	useEffect(() => {
		trackPageView({name: 'restore new password view'});
	}, []);

	return (
		<HomeLayout onBack={navigateBack}>
			<ViewTitle startAdornment={<NavigateIconButton variant="back" onClick={navigateBack} />}>
				Reset Password
			</ViewTitle>
			<Typography size={16} lineHeight={isMobile ? 24 : 20}>
				Forgot your password? No worries, it happens.
			</Typography>
			<MobilePhoneForm
				isLoading={isVerificationCodeSending}
				isPhoneVerificationError={isVerificationCodeError}
				onSubmit={handleSubmit}
			/>
		</HomeLayout>
	);
};
