import {Action} from 'redux';
import {C, Loadings} from 'src/store/constants';
import {PayloadAction} from 'src/store/types';

export type SetLoadingAction = PayloadAction<C.SET_LOADING, string>;
export type RemoveLoadingAction = PayloadAction<C.REMOVE_LOADING, string>;
export type ClearLoadingsAction = Action<C.CLEAR_LOADINGS>;

export const setLoading = (key: Loadings): SetLoadingAction => ({
	type: C.SET_LOADING,
	payload: key
});

export const removeLoading = (key: Loadings): RemoveLoadingAction => ({
	type: C.REMOVE_LOADING,
	payload: key
});

export const clearLoadings = (): ClearLoadingsAction => ({
	type: C.CLEAR_LOADINGS
});
