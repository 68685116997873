export function endsWithPending(actionType: string) {
	return Boolean(actionType.endsWith('_PENDING'));
}

export function endsWithSuccess(actionType: string) {
	return Boolean(actionType.endsWith('_SUCCESS'));
}

export function endsWithError(actionType: string) {
	return Boolean(actionType.endsWith('_ERROR'));
}
