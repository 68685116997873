import React from 'react';
import {Redirect} from '@reach/router';
import {useSelector} from 'react-redux';
import {routes} from 'src/config';
import {selectIsAuthenticated} from 'src/store/auth/selectors';
import {Route, RouteProps} from '../Route/Route';

export const AuthenticatedRoute = (props: RouteProps) => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	return isAuthenticated ? (
		<Route isAuthenticated={isAuthenticated} {...props} />
	) : (
		<Redirect to={routes.login} noThrow />
	);
};
