import {C} from 'src/store/constants';
import {GetPlatformInfoSuccessAction} from './actions';

type IntegratorState = {
	businessName?: string;
};

const initialState: IntegratorState = {businessName: ''};

const integratorReducer = (state = initialState, action: GetPlatformInfoSuccessAction) => {
	switch (action.type) {
		case C.GET_PLATFORM_INFO_SUCCESS:
			return action.payload.data;

		default:
			return state;
	}
};

export default integratorReducer;
