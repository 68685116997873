import React, {ReactNode, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as BanqLogo} from 'src/assets/banqLogo.svg';
import {Theme} from 'src/theme';
import {Layout} from '../Layout/Layout';
import {CloseIconButton} from '../CloseIconButton/CloseIconButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: (isScrolling) => ({
		width: 544,
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		padding: '28px 48px 48px 48px',
		'&::-webkit-scrollbar': {
			backgroundColor: isScrolling ? theme.colors.white : 'transparent'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: isScrolling ? theme.colors.black20 : 'transparent'
		}
	}),
	darkLogo: {
		marginLeft: -20,
		marginTop: 0,
		marginBottom: 0,
		width: 120,
		height: 80
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 25
	},
	loginHeaderActionHolder: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	viewTitleWrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginTop: 40
	},
	closeButton: {
		position: 'absolute',
		top: 25,
		right: 24
	}
}));

interface Props {
	children: ReactNode;
	className?: string;
	withoutCloseButton?: boolean;
	onClose?: () => void;
}

export const DesktopLayout = ({children, className, withoutCloseButton, onClose}: Props) => {
	const [isScrolling, setIsScrolling] = useState(false);
	const classes = useStyles(isScrolling);

	let scrollTimer = -1;

	const scrollFinished = () => setIsScrolling(false);

	const onScroll = (): void => {
		setIsScrolling(true);
		if (scrollTimer !== -1) {
			clearTimeout(scrollTimer);
		}
		scrollTimer = window.setTimeout(() => scrollFinished(), 1000);
	};

	return (
		<Layout className={clsx(classes.root, className)} onScroll={onScroll}>
			<header className={classes.header}>
				<div className={classes.loginHeaderActionHolder}>
					<BanqLogo />
					{!withoutCloseButton && <CloseIconButton onClose={onClose} />}
				</div>
			</header>
			<div>{children}</div>
		</Layout>
	);
};
