import omit from 'lodash/omit';
import {C} from '../constants';
import {ClearLoadingsAction, RemoveLoadingAction, SetLoadingAction} from './actions';
import {endsWithError, endsWithPending, endsWithSuccess} from '../utils';
import {PayloadAction} from '../types';

type LoadingsState = Record<string, boolean>;

type LoadingsActions = PayloadAction<C, string> | SetLoadingAction | RemoveLoadingAction | ClearLoadingsAction;

const initialState: LoadingsState = {};

const loadingsReducer = (state = initialState, action: LoadingsActions) => {
	switch (action.type) {
		case C.SET_LOADING:
			return {
				...state,
				[action.payload]: true
			};
		case C.REMOVE_LOADING:
			return omit(state, action.payload);
		case C.CLEAR_LOADINGS:
			return initialState;
	}

	const name = action?.loading;

	if (!name) return state;

	if (endsWithPending(action.type)) {
		return {...state, [name]: true};
	}

	if (endsWithSuccess(action.type)) {
		return omit(state, name);
	}

	if (endsWithError(action.type)) {
		return omit(state, name);
	}
	return state;
};

export default loadingsReducer;
