import {combineReducers} from 'redux';
import loadingsReducer from './@loadings/reducer';
import errorsReducer from './@errors/reducer';
import authReducer from './auth/reducer';
import messagesReducer from './@success/reducer';
import phoneVerificationReducer from './phoneVerification/reducer';
import integratorReducer from './integrator/reducer';
import confirmAccessReducer from './accessСonfirmation/reducer';

const reducer = combineReducers({
	loadings: loadingsReducer,
	errors: errorsReducer,
	messages: messagesReducer,
	auth: authReducer,
	phoneVerification: phoneVerificationReducer,
	integrator: integratorReducer,
	confirmAccess: confirmAccessReducer
});

export type State = ReturnType<typeof reducer>;

export default reducer;
