import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorMessage} from 'src/components/@shared';
import {trackEvent} from 'src/telemetry/ecommerceWidgetEvents';
import {LoginForm} from 'src/components/login/LoginForm/LoginForm';
import {selectIsLoginError, selectLoginUserLoading} from 'src/store/auth/selectors';
import {loginUser} from 'src/store/auth/actions';
import {LoginFormValues} from './types';

interface Props {
	isLoading?: boolean;
}

export const LoginContainer = ({isLoading: isExternalLoading}: Props) => {
	const dispatch = useDispatch();
	const isLoginLoading = useSelector(selectLoginUserLoading);
	const isLoginError = useSelector(selectIsLoginError);

	const isLoading = isLoginLoading || isExternalLoading;

	const handleLogin = ({email, password}: LoginFormValues) => {
		trackEvent('click: "Log In" on login view', {email});
		dispatch(loginUser({email, password}));
	};

	return (
		<>
			<ErrorMessage visible={!isLoading && isLoginError}>There was an error during authorization</ErrorMessage>
			<LoginForm isLoading={isLoading} onSubmit={handleLogin} />
		</>
	);
};
