import React from 'react';

import {Typography} from 'src/components/@shared/Typography';
import {Button} from 'src/components/@shared';
import {useStyles} from './ErrorDialog.styles';

interface Props {
	onRetry: () => void;
	onClose: () => void;
	count: number;
}

const MAX_TRIES_COUNT = 4;

export const ErrorDialog = ({onRetry, onClose, count}: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.cover}>
			<div className={classes.container}>
				<Typography size={22} lineHeight={28}>
					Whoops.
				</Typography>
				<Typography size={16} lineHeight={16} color="black50" className={classes.message}>
					{count < MAX_TRIES_COUNT
						? 'Something went wrong. Please retry.'
						: 'Something went wrong. Please try again later.'}
				</Typography>
				{count < MAX_TRIES_COUNT && (
					<Button fullWidth variant="contained" className={classes.button} gradient="popUpButton" onClick={onRetry}>
						Retry
					</Button>
				)}
				<Button
					fullWidth
					variant={count < MAX_TRIES_COUNT ? 'minimalistic' : 'contained'}
					gradient="popUpButton"
					className={classes.button}
					onClick={onClose}>
					Close
				</Button>
			</div>
		</div>
	);
};
