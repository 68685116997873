import {Action} from 'redux';
import {C} from '../constants';
import {PayloadAction} from '../types';

export type SetSuccessAction = PayloadAction<C.SET_SUCCESS, {key: string; message: unknown}>;
export type RemoveSuccessAction = PayloadAction<C.REMOVE_SUCCESS, string>;
export type ClearSuccessAction = Action<C.CLEAR_SUCCESS>;

export const setSuccess = (key: string, message: unknown = true): SetSuccessAction => ({
	type: C.SET_SUCCESS,
	payload: {key, message}
});

export const removeSuccess = (key: string): RemoveSuccessAction => ({
	type: C.REMOVE_SUCCESS,
	payload: key
});

export const clearSuccess = (): ClearSuccessAction => ({
	type: C.CLEAR_SUCCESS
});
