import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {paddingTop: 20},
	input: {
		margin: 24
	},
	forgotPassword: {
		textDecoration: 'underline',
		border: 0,
		marginTop: 24,
		marginBottom: 50,
		fontSize: 14,
		padding: 0
	}
}));
