import React from 'react';
import {useNavigate} from '@reach/router';
import {Field, TextField, PasswordField, Button, Form, Spinner} from 'src/components/@shared';
import {trackEvent} from 'src/telemetry/ecommerceWidgetEvents';
import {routes} from 'src/config';
import {useStyles} from './LoginForm.styles';
import {LoginFormValues} from '../types';
import {initialValues, validationSchema} from './constants';

interface Props {
	isLoading?: boolean;
	onSubmit: (values: LoginFormValues) => void;
}

export const LoginForm = ({isLoading, onSubmit}: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const handleRestorePassword = (): void => {
		trackEvent(`click: "Forgot Password?" link`);
		navigate(routes.restorePassword);
	};

	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			className={classes.root}>
			<Field
				data-testid="email"
				name="email"
				label="Email"
				autoCorrect="off"
				autoCapitalize="off"
				className={classes.input}
				fullWidth
				shouldTrimText
				component={TextField}
			/>
			<Field
				data-testid="password"
				name="password"
				label="Password"
				className={classes.input}
				fullWidth
				shouldTrimText
				component={PasswordField}
			/>
			<Button className={classes.forgotPassword} variant="minimalistic" onClick={handleRestorePassword} type="button">
				Forgot Password?
			</Button>
			<Button disabled={isLoading} fullWidth type="submit">
				{isLoading ? <Spinner color="mercury" size={19} /> : 'Log In'}
			</Button>
		</Form>
	);
};
