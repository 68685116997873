import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';

export const useStyles = makeStyles((theme: Theme) => ({
	content: {
		margin: '102px 0 125px 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	title: {
		lineHeight: '28px',
		fontWeight: 500,
		color: theme.colors.black
	},
	button: {
		marginTop: 14
	},
	message: {
		marginTop: 12
	},
	emoji: {
		marginLeft: 2
	}
}));
