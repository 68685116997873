import React, {ReactNode, useState, useEffect} from 'react';
import isString from 'lodash/isString';
import clsx from 'clsx';

import {delay} from 'src/utils';
import {Typography} from 'src/components/@shared';
import {useStyles} from './Snackbar.styles';

interface SnackbarProps {
	className?: string;
	children: ReactNode | string;
	variant?: 'success' | 'error';
	onClose: () => void;
}

export const Snackbar = ({className, children, variant = 'success', onClose}: SnackbarProps) => {
	const [display, setDisplay] = useState<boolean>(true);
	const classes = useStyles({display});

	useEffect(() => {
		let isUnmounted = false;
		delay(5000).then(() => {
			if (isUnmounted) return;
			setDisplay(false);
			onClose();
		});
		return () => {
			isUnmounted = true;
		};
	}, [onClose]);

	const rootClass = clsx(classes.root, className, {
		[classes.errorBackground]: variant === 'error',
		[classes.successBackground]: variant === 'success'
	});

	return (
		<div className={rootClass}>
			<div className={classes.messageContainer}>
				{isString(children) ? (
					<Typography size={16} color="white" lineHeight={22}>
						{children}
					</Typography>
				) : (
					children
				)}
			</div>
		</div>
	);
};
