import http from './http';

export interface UpdatePasswordRequest {
	phone: string;
	verificationCode: string;
	password: string;
}

export interface ValidatePhoneRequest {
	phone: string;
	verificationCode: string;
}

const phoneVerification = {
	sendVerificationCodeForResetPassword(phone: string): Promise<void> {
		return http.post('phone-verifications/reset-password-code', {
			phone,
			deliveryMethod: 'sms'
		});
	},
	sendVerificationCodeForSignup(phone: string): Promise<void> {
		return http.post('phone-verifications/verify', {
			phone,
			deliveryMethod: 'sms'
		});
	},
	validatePhone({phone, verificationCode}: ValidatePhoneRequest): Promise<void> {
		return http.post('phone-verifications/validate', {
			phone,
			verificationCode: Number(verificationCode)
		});
	},
	updatePassword({phone, verificationCode, password}: UpdatePasswordRequest): Promise<void> {
		return http.post('phone-verifications/update-password', {
			phone,
			verificationCode: Number(verificationCode),
			password
		});
	}
};

export default phoneVerification;
