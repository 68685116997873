import React from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {Field, TextField, Button, Form, Spinner} from 'src/components/@shared';
import * as yup from 'yup';
import {isMobile} from 'src/utils/isMobileDevice';

const useStyles = makeStyles(() => ({
	root: {padding: '20px 0'},
	input: {
		margin: 24
	},
	mobileButton: {
		position: 'absolute',
		bottom: 20,
		width: '90%'
	},
	mobileResendButton: {
		position: 'absolute',
		bottom: 100,
		left: 0,
		right: 0
	}
}));

const validationSchema = yup.object().shape({
	verificationCode: yup
		.string()
		.trim('The contact name cannot include leading and trailing spaces')
		.required('Verification code is a required field')
		.test('len', 'Verification code is invalid', (val) => val?.length === 6)
});

const initialValues = {
	verificationCode: ''
};

interface Props {
	isLoading?: boolean;
	onSubmit: (verificationCode: string) => void;
	isInvalidVerificationCodeError?: boolean;
	children: JSX.Element;
}

export const VerificationCodeForm = ({isLoading, onSubmit, isInvalidVerificationCodeError, children}: Props) => {
	const classes = useStyles();

	return (
		<Form
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={({verificationCode}) => onSubmit(verificationCode)}
			className={classes.root}
			initialErrors={isInvalidVerificationCodeError ? {verificationCode: 'Verification code is invalid'} : undefined}>
			<Field
				data-testid="code"
				name="verificationCode"
				label="Verification Code"
				className={classes.input}
				helperText="No code? Make sure you’ve entered a mobile number that can receive SMS messages and is NOT a virtual number."
				fullWidth
				shouldTrimText
				maxLength={16}
				component={TextField}
			/>
			<div className={clsx({[classes.mobileResendButton]: isMobile})}>{children}</div>
			<Button disabled={isLoading} fullWidth type="submit" className={isMobile ? classes.mobileButton : undefined}>
				{isLoading ? <Spinner color="mercury" size={19} /> : 'Enter'}
			</Button>
		</Form>
	);
};
