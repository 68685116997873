import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';
import {ReactComponent as OrIcon} from 'src/assets/images/orIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		background: theme.gradients.dividerLight,
		height: 1,
		margin: '28px 0'
	},
	orIcon: {
		position: 'relative',
		top: -10,
		'& path:first-child': {
			fill: theme.colors.white,
			stroke: theme.colors.lightGrey
		},
		'& path:last-child': {
			fill: theme.colors.black50,
			opacity: 1
		}
	}
}));

export const Divider = () => {
	const classes = useStyles();

	return (
		<div className={classes.divider}>
			<OrIcon className={classes.orIcon} />
		</div>
	);
};
