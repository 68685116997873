import {makeStyles} from '@material-ui/styles';

import {Theme} from 'src/theme';

interface StyleProps {
	display: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
	root: ({display}: StyleProps) => ({
		display: display ? 'block' : 'none',
		position: 'absolute',
		left: '50%',
		top: 30,
		transform: 'translateX(-50%)',
		padding: '16px 20px',
		color: theme.colors.white,
		borderRadius: 10,
		boxShadow: '0px 10px 22px rgba(118, 34, 169, 0.18)',
		zIndex: 9999
	}),
	successBackground: {
		background: theme.gradients.purpleBanner
	},
	errorBackground: {
		background: theme.gradients.red
	},
	messageContainer: {
		width: 295
	}
}));
