import omit from 'lodash/omit';
import {Dispatch, Middleware, MiddlewareAPI} from 'redux';

export const loadMiddleware: Middleware =
	({dispatch}: MiddlewareAPI) =>
	(next: Dispatch) =>
	(action) => {
		if (!action.load) return next(action);
		const loadAction = omit(action, 'load');
		dispatch({
			...loadAction,
			type: `${action.type}_PENDING`
		});
		return action.load
			.then((response: unknown) =>
				dispatch({
					...loadAction,
					type: `${action.type}_SUCCESS`,
					payload: response
				})
			)
			.catch((error: unknown) =>
				dispatch({
					...loadAction,
					type: `${action.type}_ERROR`,
					payload: error ?? new Error('Unknown error! Promise rejected without error.')
				})
			);
	};
