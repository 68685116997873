export enum C {
	LOAD_APP = 'LOAD_APP',
	LOAD_APP_SUCCESS = 'LOAD_APP_SUCCESS',
	LOGIN_USER = 'LOGIN_USER',
	LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
	LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',
	SET_LOADING = 'SET_LOADING',
	REMOVE_LOADING = 'REMOVE_LOADING',
	CLEAR_LOADINGS = 'CLEAR_LOADINGS',
	SET_ERROR = 'SET_ERROR',
	REMOVE_ERROR = 'REMOVE_ERROR',
	CLEAR_ERRORS = 'CLEAR_ERRORS',
	LOGOUT_USER = 'LOGOUT_USER',
	LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
	RESTORE_AUTH_SESSION = 'RESTORE_AUTH_SESSION',
	RESTORE_AUTH_SESSION_SUCCESS = 'RESTORE_AUTH_SESSION_SUCCESS',
	RESTORE_AUTH_SESSION_ERROR = 'RESTORE_AUTH_SESSION_ERROR',
	RESTORE_AUTH_SESSION_COMPLETE = 'RESTORE_AUTH_SESSION_COMPLETE',
	LOAD_AUTH_USER = 'LOAD_AUTH_USER',
	LOAD_AUTH_USER_SUCCESS = 'LOAD_AUTH_USER_SUCCESS',
	SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
	SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS',
	VALIDATE_PHONE = 'VALIDATE_PHONE',
	VALIDATE_PHONE_SUCCESS = 'VALIDATE_PHONE_SUCCESS',
	UPDATE_PASSWORD = 'UPDATE_PASSWORD',
	UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS',
	UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR',
	SET_SUCCESS = 'SET_SUCCESS',
	REMOVE_SUCCESS = 'REMOVE_SUCCESS',
	CLEAR_SUCCESS = 'CLEAR_SUCCESS',
	GET_PLATFORM_INFO = 'GET_PLATFORM_INFO',
	GET_PLATFORM_INFO_SUCCESS = 'GET_PLATFORM_INFO_SUCCESS',
	CONFIRM_ACCESS = 'CONFIRM_ACCESS',
	CONFIRM_ACCESS_SUCCESS = 'CONFIRM_ACCESS_SUCCESS'
}

export enum Loadings {
	isAppLoading = 'isAppLoading',
	loginUser = 'loginUser',
	logoutUserLoading = 'logoutUserLoading',
	globalLoader = 'globalLoader',
	isSendVerificationCodeLoading = 'isSendVerificationCodeLoading',
	validatePhoneLoading = 'validatePhoneLoading',
	isAuthUserLoading = 'isAuthUserLoading',
	isParsePaymentLinkLoading = 'isParsePaymentLinkLoading',
	restoreAuthSessionLoading = 'restoreAuthSessionLoading',
	updatePasswordLoading = 'updatePasswordLoading',
	isGetPlatformInfoLoading = 'isGetPlatformInfoLoading',
	confirmAccessLoading = 'confirmAccessLoading'
}

export enum Errors {
	isSendVerificationCodeError = 'isSendVerificationCodeError',
	isPhoneValidationError = 'isPhoneValidationError',
	somethingWentWrong = 'somethingWentWrong',
	updatePasswordError = 'updatePasswordError',
	updatePasswordNetworkError = 'updatePasswordNetworkError',
	snackbar = 'snackbar',
	loginUserError = 'loginUserError',
	loadAuthUserError = 'loadAuthUserError',
	restoreAuthSessionError = 'restoreAuthSessionError',
	logoutUserError = 'logoutUserError',
	isGetPlatformInfoError = 'isGetPlatformInfoError',
	confirmAccessError = 'confirmAccessError'
}

export enum Success {
	snackbar = 'snackbar',
	isLoginUserSuccessful = 'isLoginUserSuccessful',
	isPhoneValidationSuccessful = 'isPhoneValidationSuccessful',
	isSendVerificationCodeSuccessful = 'isSendVerificationCodeSuccessful',
	isUpdatePasswordSuccessful = 'isUpdatePasswordSuccessful',
	isAppLoadedSuccessfully = 'isAppLoadedSuccessfully',
	isAuthUserLoadedSuccessfully = 'isAuthUserLoadedSuccessfully',
	isNewAuthUser = 'isNewAuthUser',
	isRestoredAuthSession = 'isRestoredAuthSession',
	logoutUserSuccessful = 'logoutUserSuccessful',
	isGetPlatformInfoSuccess = 'isGetPlatformInfoSuccess',
	confirmAccessSuccess = 'confirmAccessSuccess'
}
