import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, RouteComponentProps} from '@reach/router';

import {
	selectIsAuthChecked,
	selectIsAuthenticated
	// selectIsAuthUserLoaded
} from 'src/store/auth/selectors';
import {iframe, routes} from 'src/config';
import {restoreAuthSession} from 'src/store/auth/actions';
import {analytics} from 'src/telemetry';

export const IndexView: FC<RouteComponentProps> = () => {
	const dispatch = useDispatch();
	const isAuthChecked = useSelector(selectIsAuthChecked);
	const isAuthenticated = useSelector(selectIsAuthenticated);

	useEffect(() => {
		analytics.track('Widget opened');
		if (iframe.refreshToken) {
			dispatch(restoreAuthSession(iframe.refreshToken));
		}
	}, [dispatch]);

	if (!isAuthChecked) return <></>;

	return <Redirect from="/" to={isAuthenticated ? routes.detailsScreen : routes.login} noThrow />;
};
