import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from 'src/components/@shared';
import {Theme} from 'src/theme';
import {isMobileDevice} from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
	resendCodeBtn: {
		fontWeight: 400,
		fontSize: 14,
		color: theme.colors.purple,
		borderBottom: 0,
		textDecoration: 'underline',
		margin: '100px auto 30px auto'
	}
}));

interface Props {
	isLoading?: boolean;
	onResendCode: () => void;
}

export const ResendCodeButton = ({isLoading, onResendCode}: Props) => {
	const classes = useStyles();

	return (
		<Button
			className={classes.resendCodeBtn}
			disabled={isLoading}
			variant="minimalistic"
			fullWidth
			type="button"
			onClick={onResendCode}>
			{isMobileDevice() ? 'Tap here to resend code' : 'Click here to resend code'}
		</Button>
	);
};
