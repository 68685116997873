import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsSendVerificationCodeSuccess} from 'src/store/phoneVerification/selectors';
import {sendVerificationCode, PageType} from 'src/store/phoneVerification/actions';
import {Typography} from 'src/components/@shared';
import {trackEvent} from 'src/telemetry/ecommerceWidgetEvents';
import {ResendCodeButton} from './ResendCodeButton';

const useStyles = makeStyles(() => ({
	resentCodeMsg: {
		textAlign: 'center',
		margin: '100px 0 30px 0'
	}
}));

interface Props {
	phone: string;
	pageType: PageType;
}

export const ResendCodeButtonContainer = ({phone, pageType}: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const codeResentSuccess = useSelector(selectIsSendVerificationCodeSuccess);
	const [codeResent, setCodeResent] = useState<boolean>(false);

	const onResendCode = (): void => {
		setCodeResent(true);
		trackEvent('click: "Click Here to Resend Code" on sign up');
		dispatch(sendVerificationCode(phone, pageType));
	};

	useEffect(() => {
		if (codeResent) {
			setTimeout(() => setCodeResent(false), 5000);
		}
	}, [codeResent, setCodeResent]);

	return codeResent && codeResentSuccess ? (
		<Typography className={classes.resentCodeMsg} color="dustyGray">
			Code sent!
		</Typography>
	) : (
		<ResendCodeButton onResendCode={onResendCode} />
	);
};
