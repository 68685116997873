/* eslint-disable react/button-has-type,react/jsx-props-no-spreading */
import React, {ButtonHTMLAttributes} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {Colors, Gradients, Theme} from 'src/theme';

type StyleProps = {
	fullWidth?: boolean;
	gradient?: keyof Gradients;
	color?: keyof Colors;
};

const useStyles = makeStyles((theme: Theme) => ({
	root: ({fullWidth}: StyleProps) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'Sofia Pro',
		cursor: 'pointer',
		width: fullWidth ? '100%' : 'auto'
	}),
	contained: ({gradient}: StyleProps) => ({
		padding: 16,
		background: (gradient && theme.gradients[gradient]) || theme.gradients.button,
		borderRadius: 80,
		color: theme.colors.white,
		border: 0,
		fontSize: 18,
		'&:focus': {outline: 0},
		'&:hover': {
			transition: 'all 0.25s ease-out',
			boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
			transform: 'scale(1.005)'
		}
	}),
	outlinedGradient: () => ({
		padding: 16,
		fontSize: 18,
		borderRadius: '80px',
		backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), ${theme.gradients.button}`,
		textFillColor: theme.colors.purple,
		border: 'solid 3px transparent',
		backgroundOrigin: 'border-box',
		'-webkit-background-size': 'cover',
		'-moz-background-size': 'cover',
		'-o-background-size': 'cover',
		'background-size': 'cover',
		boxShadow: `2px 1000px 1px ${theme.colors.white} inset`,
		'&:focus': {outline: 0},
		'&:hover': {
			boxShadow: `2px 1000px 1px`,
			textFillColor: theme.colors.white,
			transform: 'scale(1.01)',
			transition: 'all 0.25s ease-out'
		}
	}),
	outlined: ({color}: StyleProps) => ({
		padding: 13,
		fontSize: 18,
		borderRadius: '80px',
		border: `solid 1px`,
		borderColor: (color && theme.colors[color]) || theme.colors.purple,
		backgroundColor: theme.colors.white,
		textFillColor: (color && theme.colors[color]) || theme.colors.purple,
		boxShadow: `1px 1000px 1px ${theme.colors.white}`,
		'&:focus': {outline: 0},
		'&:hover': {
			boxShadow: `2px 1000px 1px`,
			textFillColor: theme.colors.white,
			backgroundColor: (color && theme.colors[color]) || theme.colors.purple,
			transform: 'scale(1.01)',
			transition: 'all 0.25s ease-out'
		}
	}),
	minimalistic: ({color}: StyleProps) => ({
		background: theme.colors.white,
		color: color ? theme.colors[color] : theme.colors.black70,
		border: 'none',
		padding: 16,
		outline: 'none',
		cursor: 'pointer',
		fontSize: 16,
		'&:hover': {color: theme.colors.black50}
	}),
	disabled: {
		opacity: 0.3,
		cursor: 'auto',
		'&:hover': {
			boxShadow: 'none !important' // I'm unable to override hover without important. Looks like this is material ui bug
		}
	}
}));

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	type?: 'submit' | 'button';
	variant?: 'contained' | 'outlinedGradient' | 'minimalistic' | 'outlined';
	fullWidth?: boolean;
	gradient?: keyof Gradients;
	color?: keyof Colors;
}

export const Button = ({
	className,
	disabled,
	children,
	fullWidth,
	color,
	gradient,
	variant = 'contained',
	...props
}: ButtonProps) => {
	const classes = useStyles({fullWidth, gradient, color});
	return (
		<button
			{...props}
			disabled={disabled}
			className={clsx(classes.root, classes[variant], className, {
				[classes.disabled]: disabled
			})}>
			{children}
		</button>
	);
};
