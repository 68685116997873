import http from './http';

export type PlatformInfoResponse = {
	data: {
		businessName: string;
	};
};

const integrator = {
	getInfo(guid: string): Promise<PlatformInfoResponse> {
		return http.get(`/account-spaces/${guid}/business-name`);
	}
};

export default integrator;
