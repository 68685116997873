import {C, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api, {PlatformInfoResponse} from 'src/api';

export type GetPlatformInfoAction = LoadAction<C.GET_PLATFORM_INFO>;

export type GetPlatformInfoSuccessAction = PayloadAction<C.GET_PLATFORM_INFO_SUCCESS, PlatformInfoResponse>;

export const getPlatformInfo = (platformGuid: string): GetPlatformInfoAction => ({
	type: C.GET_PLATFORM_INFO,
	load: api.integrator.getInfo(platformGuid),
	loading: Loadings.isGetPlatformInfoLoading,
	error: Errors.isGetPlatformInfoError,
	success: Success.isGetPlatformInfoSuccess
});
