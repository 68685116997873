import auth from './auth';
import phoneVerification from './phoneVerification';
import integrator from './integrator';
import linkingPermissions from './linkingPermissions';

export * from './auth';
export * from './integrator';
export * from './types';
export * from './linkingPermissions';

const api = {
	auth,
	phoneVerification,
	integrator,
	linkingPermissions
};

export type API = typeof api;

export default api;
