import React from 'react';
import {useDispatch} from 'react-redux';

import {Typography, Button, HomeLayout} from 'src/components/@shared';

import {logoutUser} from 'src/store/auth/actions';
import {useStyles} from './LoginAlreadyLinkedView.styles';

export const LoginAlreadyLinkedView = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleClose = () => dispatch(logoutUser());

	return (
		<HomeLayout withoutCloseButton>
			<div className={classes.content}>
				<Typography size={22} lineHeight={28} className={classes.title}>
					You’ve already linked your banq wallet!
				</Typography>
				<Typography size={16} lineHeight={16} color="black50" className={classes.message}>
					Nice, you’re on top of it.
				</Typography>
			</div>
			<Button className={classes.button} fullWidth onClick={handleClose}>
				Yay!
			</Button>
		</HomeLayout>
	);
};
