import React from 'react';
import {useDispatch} from 'react-redux';
import {logoutUser} from 'src/store/auth/actions';
import {ReactComponent as CloseIcon} from 'src/assets/closeButton.svg';
import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';
import {IconButton, IconButtonProps} from 'src/components/@shared/IconButton/IconButton';

export enum VariantEnum {
	dark = 'dark',
	light = 'light'
}

const useStyles = makeStyles((theme: Theme) => ({
	closeIcon: ({variant}: {variant: VariantEnum}) => ({
		width: 20,
		height: 20,
		fill: variant === VariantEnum.dark ? theme.colors.black30 : theme.colors.white,
		opacity: variant === VariantEnum.dark ? 0.6 : 1
	})
}));

interface Props extends Omit<IconButtonProps, 'onClick'> {
	variant?: VariantEnum;
	onClose?: () => void;
}
export const CloseIconButton = ({variant = VariantEnum.dark, onClose, ...props}: Props) => {
	const dispatch = useDispatch();
	const classes = useStyles({variant});

	return (
		<IconButton {...props} onClick={() => (onClose ? onClose() : dispatch(logoutUser()))}>
			<CloseIcon className={classes.closeIcon} />
		</IconButton>
	);
};
