import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Typography, Button, HomeLayout} from 'src/components/@shared';
import {Emoji} from 'src/components/@shared/Emoji/Emoji';
import Confetti from 'src/assets/images/confetti.png';

import {logoutUser} from 'src/store/auth/actions';
import {selectPlatformBusinessName} from 'src/store/integrator/selectors';
import {useStyles} from './LoginSuccessView.styles';

export const LoginSuccessView = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const marketplace = useSelector(selectPlatformBusinessName);

	const handleClose = () => dispatch(logoutUser());

	return (
		<HomeLayout withoutCloseButton>
			<div className={classes.content}>
				<Typography size={22} lineHeight={28} className={classes.title}>
					Success!
					<Emoji className={classes.emoji} src={Confetti} height={22} />
				</Typography>
				<Typography size={16} lineHeight={16} color="black50" className={classes.message}>
					You linked your banq wallet to {marketplace}!
				</Typography>
			</div>
			<Button className={classes.button} fullWidth onClick={handleClose}>
				Yay!
			</Button>
		</HomeLayout>
	);
};
