import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import api from 'src/api';
import reducers from './reducer';
import sagas from './sagas';
import {logoutUser} from './auth/actions';
import {loadMiddleware} from './loadMiddleware';

const sagaMiddleware = createSagaMiddleware({context: {api}});

const store = createStore(reducers, composeWithDevTools(applyMiddleware(loadMiddleware, sagaMiddleware)));

sagaMiddleware.run(sagas);

window.addEventListener('logout', () => store.dispatch(logoutUser() as never));

export default store;
