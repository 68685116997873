import {C} from 'src/store/constants';
import {produce} from 'immer';
import {User} from 'src/api/types/users';
import {
	LoadAuthUserAction,
	LoadAuthUserSuccessAction,
	LoginUserSuccessAction,
	LogoutUserSuccessAction,
	RestoreAuthSessionCompleteAction,
	RestoreAuthSessionSuccessAction
} from './actions';

type AuthActions =
	| RestoreAuthSessionSuccessAction
	| RestoreAuthSessionCompleteAction
	| LoadAuthUserAction
	| LoginUserSuccessAction
	| LogoutUserSuccessAction
	| LoadAuthUserSuccessAction;

type AuthState = {
	isAuthChecked: boolean;
	accessToken?: string;
	userId?: number;
	user?: User;
	isDemoUserBrandNew?: boolean;
};
const initialState: AuthState = {
	isAuthChecked: false,
	accessToken: undefined,
	userId: undefined,
	user: undefined,
	isDemoUserBrandNew: false
};

const authReducer = produce((draft, action: AuthActions) => {
	switch (action.type) {
		case C.LOGIN_USER_SUCCESS:
			draft.expiresIn = action.payload.expires_in;
			draft.accessToken = action.payload.access_token;
			break;

		case C.LOAD_AUTH_USER_SUCCESS:
			draft.userId = action.payload.id;
			draft.user = action.payload.user;
			break;

		case C.RESTORE_AUTH_SESSION_COMPLETE:
			draft.isAuthChecked = true;
			break;
	}
}, initialState);

export default authReducer;
