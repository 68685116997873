import {ICustomProperties} from '@microsoft/applicationinsights-core-js';
import {appInsights} from './AppInsights';
import {analytics} from './analytics';

export interface EventProperties<P> {
	name: string;
	additionalProperties?: {[key: string]: P};
}

export interface PageViewI {
	/**
	 * name String - The string you used as the name in startTrackPage. Defaults to the document title.
	 */
	name?: string;
	/**
	 * uri  String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
	 */
	uri?: string;
	/**
	 * isLoggedIn - boolean is user logged in
	 */
	isLoggedIn?: boolean;
	/**
	 * Property bag to contain additional custom properties (Part C)
	 */
	properties?: {
		/**
		 * The number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
		 */
		duration?: number;
	};
}

export interface ExceptionI<P> {
	exception: Error;
	properties?: {[key: string]: P};
}

/**
 *
 * For click events
 *
 * @param eventName
 * @param properties
 */
const trackEvent = (eventName: string, properties?: ICustomProperties): void => {
	analytics.track(eventName, properties);
	appInsights.trackEvent({name: eventName, properties});
};

/**
 *
 * For the successful operation, like after adding credit card or quote submission.
 *
 * @param {EventProperties} eventData
 */
const fireSuccessfulOperation = <K>(eventData: EventProperties<K>): void => {
	appInsights.trackEvent({name: eventData.name, properties: {...eventData?.additionalProperties}});
};

/**
 *
 * Logs that a page or other item was viewed.
 * @param {PageViewI} eventData
 */
const trackPageView = (eventData: PageViewI): void => {
	analytics.page({name: eventData.name});
	appInsights.trackPageView({
		name: eventData.name,
		properties: {...eventData?.properties},
		uri: eventData?.uri,
		isLoggedIn: eventData?.isLoggedIn
	});
};

const trackException = (exception: Error, customProperties?: ICustomProperties): void => {
	appInsights.trackException({exception}, customProperties);
};

export {trackEvent, fireSuccessfulOperation, trackPageView, trackException};
