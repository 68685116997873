import {State} from '../reducer';
import {Errors, Loadings, Success} from '../constants';

export const selectIsSendVerificationCodeSuccess = (state: State) =>
	Boolean(state.messages[Success.isSendVerificationCodeSuccessful]);

export const selectIsSendVerificationCodeLoading = (state: State) =>
	Boolean(state.loadings[Loadings.isSendVerificationCodeLoading]);

export const selectIsSendVerificationCodeError = (state: State) =>
	Boolean(state.errors[Errors.isSendVerificationCodeError]);

export const selectSendVerificationCodeError = (state: State) => state.errors[Errors.isSendVerificationCodeError];

export const selectIsPhoneValidationSuccessful = (state: State) =>
	Boolean(state.messages[Success.isPhoneValidationSuccessful]);

export const selectIsPhoneValidationLoading = (state: State) => state.loadings[Loadings.validatePhoneLoading];

export const selectIsPhoneValidationError = (state: State) =>
	!selectIsPhoneValidationLoading(state) && Boolean(state.errors[Errors.isPhoneValidationError]);

export const selectSomethingWentWrongPhoneValidationError = (state: State) =>
	!selectIsPhoneValidationLoading(state) && state.errors[Errors.somethingWentWrong];

export const selectUserPhoneNumber = (state: State) => state.phoneVerification.phone;
