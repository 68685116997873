import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {useNavigate} from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';
import {routes} from 'src/config';
import {HomeLayout, ViewTitle, NavigateIconButton, Typography, VerificationCodeForm} from 'src/components';
import {clearErrors} from 'src/store/@errors/actions';
import {formatPhoneNumber} from 'src/utils';
import {
	selectUserPhoneNumber,
	selectIsPhoneValidationSuccessful,
	selectIsPhoneValidationLoading,
	selectIsPhoneValidationError
} from 'src/store/phoneVerification/selectors';
import {validatePhone} from 'src/store/phoneVerification/actions';
import {clearSuccess} from 'src/store/@success/actions';
import {ResendCodeButtonContainer} from 'src/components/@shared';
import {trackEvent, trackPageView} from 'src/telemetry/ecommerceWidgetEvents';

const useStyles = makeStyles(() => ({
	enterCode: {
		padding: '16px 0'
	}
}));

const initialFormState = {
	phone: '',
	verificationCode: ''
};

export const ResetPasswordVerificationCodeView = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const phone = useSelector(selectUserPhoneNumber);
	const isPhoneValidationSuccessful = useSelector(selectIsPhoneValidationSuccessful);
	const isLoading = useSelector(selectIsPhoneValidationLoading);
	const isPhoneValidationFailed = useSelector(selectIsPhoneValidationError);
	const [formState, setFormState] = useState<{phone: string; verificationCode: string}>(initialFormState);

	if (!phone) throw new Error('Phone not found in state');

	useEffect(() => {
		if (!isPhoneValidationSuccessful) return;
		const state = {phone: formState.phone, verificationCode: formState.verificationCode};
		trackEvent(`success: phone validation on reset password verification view`);
		navigate(routes.createNewPassword, {state});
	}, [isPhoneValidationSuccessful, formState, navigate]);

	const handleSubmit = (verificationCode: string): void => {
		trackEvent(`click: "Next" on reset password verification view`);
		dispatch(validatePhone(phone, verificationCode));
		setFormState({phone, verificationCode});
	};

	const navigateBack = () => {
		trackEvent(`click: "Back" button on reset password verification view`);
		dispatch(clearErrors());
		dispatch(clearSuccess());
		navigate(routes.restorePassword);
	};

	useEffect(() => {
		trackPageView({name: 'reset password: on reset password verification view'});
	}, []);

	return (
		<HomeLayout onBack={navigateBack}>
			<ViewTitle startAdornment={<NavigateIconButton variant="back" onClick={navigateBack} />}>
				Reset Password
			</ViewTitle>
			<Typography size={18}>We sent you a text</Typography>
			<Typography className={classes.enterCode} size={16}>
				{`Enter your code sent to ${formatPhoneNumber(phone)}`}
			</Typography>
			<VerificationCodeForm
				isLoading={isLoading}
				onSubmit={handleSubmit}
				isInvalidVerificationCodeError={isPhoneValidationFailed}>
				<ResendCodeButtonContainer phone={phone} pageType="resetPassword" />
			</VerificationCodeForm>
		</HomeLayout>
	);
};
