import React, {ReactNode} from 'react';
import Measure, {BoundingRect} from 'react-measure';
import {PostMessageTypes} from 'src/types';
import {isMobileDevice, sendPostMessage} from 'src/utils';
import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';
import clsx from 'clsx';
import {iframe} from 'src/config';

const resize = (dimensions: BoundingRect | undefined): void => {
	if (!dimensions) return;
	if (!dimensions.width && !dimensions.height) return;
	sendPostMessage(PostMessageTypes.styles, {
		width: isMobileDevice() ? `${iframe.windowWidth}px` : `${dimensions.width}px`,
		height: isMobileDevice() ? `${iframe.windowHeight}px` : `${dimensions.height}px`,
		borderRadius: isMobileDevice() ? `0px` : `10px`
	});
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		overflowY: 'auto',
		maxHeight: iframe.windowHeight * 0.9,
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.15)',
		borderRadius: 10,
		[theme.breakpoints.down.sm]: {
			flexGrow: 1,
			maxHeight: iframe.windowHeight,
			height: iframe.windowHeight,
			width: iframe.windowWidth
		}
	}
}));
interface Props {
	className?: string;
	onScroll?: () => void;
	children: ReactNode;
}

export const Layout = ({className, onScroll, children}: Props) => {
	const classes = useStyles();

	return (
		<Measure bounds onResize={(contentRect) => resize(contentRect.bounds)}>
			{({measureRef}) => (
				<div onScroll={onScroll} ref={measureRef} className={clsx(classes.root, className)}>
					{children}
				</div>
			)}
		</Measure>
	);
};
