import React from 'react';
import {useSelector} from 'react-redux';
import {Backdrop, Spinner} from 'src/components/@shared';
import {selectIsAnyFromLoadingListLoading} from 'src/store/@loadings/selectors';

interface Props {
	isLoading?: boolean;
}
export const LoaderWithBackdrop = ({isLoading}: Props) => {
	const isGlobalLoading = useSelector(selectIsAnyFromLoadingListLoading);

	return (
		<>
			{(isGlobalLoading || isLoading) && (
				<Backdrop background={isLoading ? 'whiteSemiTransparent' : 'white'}>
					<Spinner />
				</Backdrop>
			)}
		</>
	);
};
