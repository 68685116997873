import React, {ReactNode} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Theme} from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		mixBlendMode: 'normal',
		alignItems: 'center',
		maxWidth: '100%',
		height: 95
	},
	cardBorder: {
		borderTop: `1px solid ${theme.colors.gallery}`,
		borderBottom: `1px solid ${theme.colors.gallery}`
	},
	clickable: {
		cursor: 'pointer'
	},
	disabled: {
		opacity: 0.3,
		pointerEvents: 'none'
	}
}));

interface Props {
	className?: string;
	children: ReactNode;
	onClick?: () => void;
	disabled?: boolean;
}

export const ListItem = ({className, children, onClick, disabled = false}: Props) => {
	const classes = useStyles();

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			className={clsx(
				classes.root,
				classes.cardBorder,
				{[classes.clickable]: Boolean(onClick), [classes.disabled]: disabled},
				className
			)}
			onClick={onClick}>
			{children}
		</div>
	);
};
